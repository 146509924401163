<template>
  <div class="content-box" @click="closewindow">
    <img src="../../../assets/img/successimg.png" alt="" srcset="" />
  </div>
</template>

<script>
export default {
  name: "SuccessPage",
  data() {
    return {};
  },
  methods: {
    /* 关闭当前页面 */
    closewindow() {
      if (this.isWeixin()) {
        window.WeixinJSBridge.call("closeWindow");
      } else {
        // window.AliPayJSBridge.call("closeWindow");
        window.opener = null;
        window.open("about:blank", "_self").close();
      }
    },
    /* 检查环境 */
    isWeixin() {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        console.log("微信");
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
  }
}
</style>

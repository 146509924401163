import axios from 'axios'
import {
    Toast
} from 'vant';
const request = axios.create({
    baseURL: '/api',
    // widthCredentials: true,
    // timeout: 5000
})
//请求拦截
request.interceptors.request.use(
    (config) => {
        console.log('请求输出', config)
        config.headers['Content-Type'] = 'multipart/form-data'
        const header = JSON.parse(localStorage.getItem('header'))
        console.log(header)
        if (header) {
            config.headers['token'] = header['token']
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)
//响应拦截
request.interceptors.response.use(
    response => {
        console.log('响应输出', response)
        if (response.data.code === 0) {
            return response.data
        } else {
            Toast(response.data.msg);
            return response.data
        }
    },
    error => {
        console.log('响应报错', error)
        error.code == "ECONNABORTED" && Toast.fail('网络异常,请稍后再试')
        return Promise.reject(error);
    }
)
export default request

<template>
  <div>
    <div class="back">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">项目列表</div>
    </div>
    <div class="project_title">
      <div class="project_box">
        <img class="project_img" src="../../assets/img/icon_head.png" alt="" />
        <div class="project_teacher">你好，{{ teacher_name }}</div>
      </div>
      <div class="project_exit" @click="exitClick">退出</div>
    </div>
    <!-- <van-loading class="load" v-if="loadShow" size="35px"
      >加载中...</van-loading
    > -->
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="project"
    >
      <div class="project_list_box">
        <div class="project_list" v-for="(item, index) in list" :key="index">
          <div class="project_list_title">
            <div>{{ item.project_name }}</div>
            <div class="project_btn" @click="gotoStudent(item)">进入</div>
          </div>
          <van-divider />
          <div class="group_box">
            <div>
              <div>所在组别：{{ item.group_name }}</div>
              <div class="txt">组长：{{ item.leader_name }}</div>
              <div>项目开始时间：{{ item.start_time }}</div>
            </div>
            <div class="project_exit" @click="lookClick(item)">查看缴费码</div>
          </div>
        </div>
      </div>
    </van-list>
    <van-empty description="暂无数据" v-if="list.length == 0" />
  </div>
</template>
<script>
import { Toast } from "vant";
import { getProjectData } from "@/api/xinhua/project_list.js";
export default {
  data() {
    return {
      list: [],
      // loadShow: true,
      page: 1,
      teacher_name: "",
      loading: false,
      finished: false,
    };
  },
  mounted() {
    this.teacher_name = JSON.parse(
      localStorage.getItem("teacherInfo")
    ).username;
    // this.project();
  },
  methods: {
    //获取项目列表
    project() {
      let headers = {
        token: JSON.parse(localStorage.getItem("teacherInfo")).token,
      };
      let page = {
        page: this.page,
      };
      const data = {
        request: JSON.stringify(page),
      };
      getProjectData(headers, data).then((res) => {
        this.loadShow = false;
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        this.list = this.list.concat(res.data.data);
        this.page++;
        this.loading = false;
        this.total = res.data.total;
        this.list.length >= this.total && (this.finished = true);
      });
    },
    //缴费码
    lookClick(val) {
      this.$router.push({
        path: "/projectCode",
        query: {
          list_name: val.project_name,
          url: val.project_qrcode,
        },
      });
    },

    gotoStudent(val) {
      this.$router.push({
        path: "/studentInfo",
        query: {
          group_id: val.group_id,
          project_id: val.project_id,
        },
      });
    },
    //退出
    exitClick() {
      localStorage.clear();
      this.$router.push("/projectLogin");
    },
  },
};
</script>

<style lang="less"  scoped>
.back {
  display: flex;
  align-items: center;;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image{
  width: 20px;
  height: 20px;
  margin-left:0.2rem;
}
.back_txt{
  margin:0 35%;
  text-align: center;
}
.load {
  text-align: center;
  padding: 0.2rem;
}
.project_title {
  margin-top: 0.2rem;
  background-color: #fff;
  width: 94.8%;
  height: 0.83rem;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project_box {
  display: flex;
  align-items: center;
}
.project_img {
  width: 0.88rem;
  height: 0.88rem;
}
.project_teacher {
  font-weight: bold;
  padding-left: 0.2rem;
}
.project_exit {
  color: #4581fc;
}
.project_list_box {
  background: #fff;
  width: 100%;
  margin-top: 0.2rem;
  padding: 0.15rem 0;
}
.project_list {
  margin: 0.2rem auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0.1rem 0.2rem #b1c6ff52;
}
.project_list_title {
  padding-top: 0.3rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.project_btn {
  width: 48px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #fff;
  border-radius: 12.5px;
  background: #4581fc;
}
.group_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.2rem;
  padding-bottom: 0.3rem;
}
.txt {
  padding: 0.2rem 0;
}
</style>
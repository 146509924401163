import request from '@/utils/request_base.js'
/* 登录 */
const toLogin = (data) => {
	return request({
		url: '/h5.index.project.codelogin',
		method: 'post',
		data
	})
}
/* 请求表单信息 */
const onlyLoginForm = (headers, data) => {
	return request({
		url: '/h5.project.template',
		method: 'post',
		headers,
		data
	})
}
/* 提交 */
const submitForm = (headers, data) => {
	return request({
		url: '/h5.order.savestandinfo',
		method: 'post',
		headers,
		data: data
	})
}
/* 暂存信息 */
const saveForm = (headers, data) => {
	return request({
		url: '/h5.order.savestandinfo',
		method: 'post',
		headers,
		data: data
	})
}
/* 请求订单信息 */
const getUserInfo = (headers) => {
	return request({
		url: '/h5.index.student.info',
		method: 'post',
		headers,
	})
}
/* 请求通知书 */
const getNotification = (headers, data) => {
	return request({
		url: '/h5.index.advince.downloadadvince',
		method: 'post',
		headers,
		data
	})
}
export {
	toLogin,
	onlyLoginForm,
	submitForm,
	saveForm,
	getUserInfo,
	getNotification
}
<template>
  <div>
    <div class="back" @click="backClick">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">学生列表</div>
    </div>
    <van-search
      v-model="keyword"
      show-action
      :clearable="false"
      placeholder="请输入搜索关键词"
      @search="onSearch"
    >
      <template #action>
        <div class="search">
          <div @click="onSearch" class="search_txt">搜索</div>
          <img
            class="add_img"
            src="../../assets/img/add.png"
            alt=""
            @click="addStudent"
          />
        </div>
      </template>
    </van-search>
    <!-- <van-loading class="load" v-if="loadShow" size="35px"
      >加载中...</van-loading
    > -->

    <div class="project_list_box">
      <div class="list_title">
        <div>
          总人数：<span class="span">{{ total }}</span>
        </div>
        <div>
          已缴费：<span class="span">{{ payCount }}</span>
        </div>
        <div>
          待缴费：<span class="span">{{ pending_pay }}</span>
        </div>
      </div>
      <van-list v-model="loading" :finished="finished" @load="list">
        <div
          class="project_list"
          v-for="(item, index) in listData"
          :key="index"
        >
          <div class="project_list_title">
            <div>{{ item.name }}</div>
            <van-switch
              v-model="item.checked"
              v-if="is_leader == 1 && item.is_switch == 1"
              size="24px"
              @click="switchClick(item)"
            />
          </div>
          <van-divider />
          <div class="group_box">
            <div class="group" style="">
              <div>{{item.gender==1?"男":"女"}}</div>
              <div style="margin: 0 0.2rem">{{ item.mobile }}</div>
            </div>
            <div class="group">
              <div class="txt">学校：{{ item.finish_school }}</div>
              <div style="margin: 0 0.5rem">一模成绩：{{ item.last_score }}分</div>
            </div>
            <div class="group" style="justify-content: space-between">
              <div>添加时间：{{ item.create_date }}</div>
              <div>{{ item.status_name }}</div>
            </div>
          </div>
        </div>
      </van-list>
      <van-empty description="暂无数据" v-if="listData.length == 0" />
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { studentData, switchClick } from "@/api/xinhua/project_list.js";

export default {
  data() {
    return {
      value: "",
      projectId: "",
      groupId: "",
      keyword: "",
      // loadShow: true,
      listData: [],
      total: 0,
      payCount: 0,
      pending_pay: 0,
      is_leader: "",
      loading: false,
      finished: false,
      page: 1,
    };
  },
  mounted() {
    this.projectId = this.$route.query.project_id;
    this.groupId = this.$route.query.group_id;
  },
  methods: {
    switchClick(item) {
      let headers = {
        token: JSON.parse(localStorage.getItem("teacherInfo")).token,
      };
      let val = {
        student_id: item.student_id,
        project_id: item.project_id,
        group_id: this.groupId,
        open_pay_status: item.open_pay_status,
      };
      const data = {
        request: JSON.stringify(val),
      };
      switchClick(headers, data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
      });
    },
    //列表
    list() {
      let headers = {
        token: JSON.parse(localStorage.getItem("teacherInfo")).token,
      };
      let page = {
        page: this.page,
        project_id: this.projectId,
        group_id: this.groupId,
        name: this.keyword,
      };
      const data = {
        request: JSON.stringify(page),
      };
      studentData(headers, data).then((res) => {
        this.loadShow = false;
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        res.data.data.forEach((item) => {
          item.checked = item.open_pay_status == 1 ? true : false;
        });
        this.listData = this.listData.concat(res.data.data);
        this.total = res.data.total;
        this.payCount = res.pay;
        this.pending_pay = res.pending_pay;
        this.is_leader = res.is_leader;
        this.loading = false;
        this.page++;
        this.listData.length >= this.total && (this.finished = true);
      });
    },
    //搜索
    onSearch(val) {
      console.log("搜索", val);
      this.page = 1;
      this.listData = [];
      this.list();
    },

    //添加
    addStudent() {
      this.$router.push({
        path: "/addStudent",
        query: {
          project_id: this.projectId,
        },
      });
    },
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image {
  width: 20px;
  height: 20px;
  margin-left: 0.2rem;
}
.back_txt {
  margin: 0 35%;
  text-align: center;
}
.load {
  text-align: center;
  padding: 0.2rem;
}
.search {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search_txt {
  color: #4581fc;
}
.add_img {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 0.2rem;
}

.span {
  font-weight: bold;
  color: #222;
}
.project_list_box {
  background: #fff;
  width: 100%;
  margin-top: 0.2rem;
  padding: 0.15rem 0;
}
.project_list {
  margin: 0.2rem auto;
  // width: 335px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0.1rem 0.2rem #b1c6ff52;
}
.project_list_title {
  padding-top: 0.3rem;
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem 0.5rem;
  color: #8c8c8c;
}
.txt {
  padding: 0.2rem 0;
}
.group_box {
  padding: 0 0.2rem;
  padding-bottom: 0.3rem;
}
.group {
  color: #8c8c8c;
  display: flex;
  align-items: center;
}
</style>
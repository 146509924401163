
<template>
  <div style="position: relative">
    <div class="head">
      <div class="head_title">凡高智慧校园</div>
    </div>
    <div class="content">
      <div class="box">
        <div class="box_line"></div>
        <div class="box_title">查询学生账单</div>
      </div>
      <input
        class="int"
        v-model="infoList.mobile"
        type="tel"
        maxlength="11"
        placeholder="请输入在学校预留的手机号"
      />
      <div class="int_box">
        <input
          v-model="infoList.code"
          type="text"
          style="background-color: transparent"
          placeholder="请输入手机验证码"
          @blur="checkCodeChange"
        />
        <div class="sms_txt" @click="show ? getCodeChange() : ''">
          {{ show ? "获取验证码" : count + "s" }}
        </div>
      </div>
      <div class="btn" @click="searchBill">查询订单</div>
      <van-checkbox v-model="checked" icon-size="14px"
        >授权并同意<span class="sms_txt">《凡高智慧校园》</span
        >服务协议</van-checkbox
      >
    </div>
  </div>
</template>
<script>
// import * as dd from "dingtalk-jsapi";
import { bind_user, get_code, check_code } from "@/api/DD/user.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      checked: false,
      infoList: {
        mobile: "",
        code: "",
      },
      count: 60,
      show: true,
      user_id: "",
      code_list: "",
      corpId_list: "",
    };
  },
  async mounted() {
    this.user_id = this.$route.query.user_id;
    this.corpId_list = this.$route.query.corpId_list;
    this.code_list = this.$route.query.code_list;
    // this.corpId_list = await this.getUrlParams();
    // this.getCode(this.corpId_list);
  },

  methods: {
    // getCode(val) {
    //   var that = this;
    //   dd.ready(function () {
    //     dd.runtime.permission.requestAuthCode({
    //       corpId: val, // 企业id
    //       onSuccess: function (info) {
    //         that.code_list = info.code; // 通过该免登授权码可以获取用户身份
    //         that.getUserList(info.code);
    //       },
    //       onFail: function (err) {
    //         console.log(err);
    //       },
    //     });
    //   });
    // },
    // /*获取url参数*/
    // getUrlParams() {
    //   const url_string = window.location.href;
    //   const url = new URL(url_string);
    //   const corpId = url.searchParams.get("corpId")
    //     ? url.searchParams.get("corpId")
    //     : "dingd41f50827dab99a1a1320dcb25e91351";
    //   return corpId;
    // },
    // getUserList(val) {
    //   const data = {
    //     auth_corp_id: this.corpId_list,
    //     code: val,
    //   };
    //   get_userInfo({ request: JSON.stringify(data) }).then((res) => {
    //     if (res.code != 0) {
    //       return Toast.fail(res.msg);
    //     }
    //     this.user_id = res.data.user_id;
    //     this.infoList.mobile = res.data.parent_mobile;
    //     if (
    //       res.data.parent_mobile != "" &&
    //       res.data.parent_mobile != null &&
    //       res.data.parent_mobile != undefined
    //     ) {
    //       this.$router.replace({
    //         path: "/Ding/BillList",
    //         query: {
    //           mobile: res.data.parent_mobile,
    //         },
    //       });
    //     }
    //   });
    // },
    searchBill() {
      if (this.infoList.mobile == "") {
        return Toast.fail("请输入手机号");
      }
      if (this.infoList.code == "") {
        return Toast.fail("请输入验证码");
      }
      if (this.checked == false) {
        return Toast.fail("请授权协议");
      }
      const data = {
        user_id: this.user_id,
        parent_mobile: this.infoList.mobile,
        auth_corp_id: this.corpId_list,
      };
      bind_user({ request: JSON.stringify(data) }).then((res) => {
        console.log(res, "用户信息");
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        this.$router.replace({
          path: "/Ding/BillList",
          query: {
            mobile: this.infoList.mobile,
            auth_corp_id: this.corpId_list,
          },
        });
      });
    },
    checkCodeChange() {
      if (this.infoList.mobile == "") {
        return Toast.fail("请输入手机号");
      }
      if (this.infoList.code == "") {
        return Toast.fail("请输入验证码");
      }
      const data = {
        verify_code: this.infoList.code,
        mobile: this.infoList.mobile,
      };
      check_code({ request: JSON.stringify(data) }).then((res) => {
        console.log(res, "校验手机号");
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
      });
    },
    getCodeChange() {
      if (this.infoList.mobile == "") {
        return Toast.fail("请输入手机号");
      }
      if (!/^1[3456789]\d{9}$/.test(this.infoList.mobile)) {
        return Toast.fail("手机号格式不正确");
      }
      const data = {
        mobile: this.infoList.mobile,
      };
      get_code({ request: JSON.stringify(data) }).then((res) => {
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        this.show = false;
        this.getTime();
      });
      this.getTime();
    },
    getTime() {
      var timer = setInterval(() => {
        if (this.count < 1) {
          this.show = true;
          this.count = 60;
          clearInterval(timer); // 清空定时器
        }
        this.count--;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.head {
  height: 211px;
  background: url("../../assets/img/icon_beg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.head_img {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 57px;
  left: 20px;
}
.head_title {
  font-family: ".PingFang SC";
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  color: #fff;
  text-shadow: 0 2px 3px #24242459;
  position: absolute;
  left: 20px;
  top: 109px;
}
.content {
  border-radius: 30px 30px 0 0;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 181px;
  height: 100vh;
}
.box {
  display: flex;
  align-items: center;
  padding: 29px 0;
}
.box_line {
  width: 2px;
  height: 14px;
  border-radius: 1px;
  background: #4581fc;
  margin-left: 20px;
  margin-right: 8px;
}
.box_title {
  font-family: ".PingFang SC";
  font-weight: bold;
  font-size: 18px;
  text-align: left;
  color: #101724;
}
.int {
  width: 335px;
  height: 48px;
  line-height: 48px;
  border-radius: 14px;
  background: #f4f6f8;
  margin: 0 20px;
  text-indent: 20px;
}
.sms_txt {
  font-family: ".PingFang SC";
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #4581fc;
}
.int_box {
  width: 335px;
  height: 48px;
  border-radius: 14px;
  background: #f4f6f8;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btn {
  width: 335px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: #4581fc;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin: 0 auto;
}
.van-checkbox {
  justify-content: center !important;
  padding-top: 16px !important;
}
</style>
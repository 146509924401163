import request from '@/utils/request_base.js'
/* 获取wxopenid */
const getOpenId = (data) => {
	return request({
		url: '/h5.index.project.openid',
		method: 'post',
		data: data
	})
}
/* 获取支付信息 */
const getPayInfo = (headers, data) => {
	return request({
		url: '/h5.order.payment',
		method: 'post',
		headers,
		data: data
	})
}
/* 上传通知书 */
const uploadNotice = (headers, data) => {
	return request({
		url: '/h5.index.student.uploadadvince',
		method: 'post',
		headers,
		data: data
	})
}
export {
	getOpenId,
	getPayInfo,
	uploadNotice
}
<template>
  <div style="overflow-y: hidden; height: 100%">
    <img class="img" src="../../assets/img/icon_start.png" alt="" />
  </div>
</template>

<script>
import * as dd from "dingtalk-jsapi";
import { get_userInfo } from "@/api/DD/user.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      checked: false,
      infoList: {
        mobile: "",
        code: "",
      },
      user_id: "",
      code_list: "",
      corpId_list: "",
    };
  },
  async mounted() {
    this.corpId_list = await this.getUrlParams();
    this.getCode(this.corpId_list);
  },

  methods: {
    getCode(val) {
      var that = this;
      dd.ready(function () {
        dd.runtime.permission.requestAuthCode({
          corpId: val, // 企业id
          onSuccess: function (info) {
            that.code_list = info.code; // 通过该免登授权码可以获取用户身份
            that.getUserList(info.code);
          },
          onFail: function (err) {
            console.log(err);
          },
        });
      });
    },
    /*获取url参数*/
    getUrlParams() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      const corpId = url.searchParams.get("corpId")
        ? url.searchParams.get("corpId")
        : "dingd41f50827dab99a1a1320dcb25e91351";
      return corpId;
    },
    getUserList(val) {
      const data = {
        auth_corp_id: this.corpId_list,
        code: val,
      };
      get_userInfo({ request: JSON.stringify(data) }).then((res) => {
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        this.user_id = res.data.user_id;
        this.infoList.mobile = res.data.parent_mobile;
        if (
          res.data.parent_mobile != "" &&
          res.data.parent_mobile != null &&
          res.data.parent_mobile != undefined
        ) {
          this.$router.replace({
            path: "/Ding/BillList",
            query: {
              mobile: res.data.parent_mobile,
            },
          });
        } else {
          this.$router.replace({
            path: "/Ding/userList",
            query: {
              user_id: this.user_id,
              corpId_list: this.corpId_list,
              code_list: this.code_list,
            },
          });
        }
      });
    },
  },
};
</script>



<style scoped>
body {
  overflow: hidden;
}
.img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
}
</style>
<template>
  <div v-cloak>
    <div class="img-box">
      <img src="../../assets/img/huamei.jpg" alt="" />
    </div>
    <van-form @submit="toReviewPage">
      <van-cell-group>
        <van-field
          v-model="name"
          border
          size="large"
          type="text"
          required
          label="学生姓名"
          placeholder="请输入学生姓名"
          :rules="[{ required: true }]"
        />
        <van-field name="checkboxGroup" label="性别" size="large" required>
          <template #input>
            <van-radio-group v-model="gender" direction="horizontal">
              <van-radio name="1">男</van-radio>
              <van-radio name="2">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="minzu"
          border
          size="large"
          type="text"
          required
          label="民族"
          placeholder="请输入民族"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="id_card"
          border
          size="large"
          type="idcard"
          required
          label="身份证号"
          placeholder="请输入身份证号"
          :rules="[
            { required: true, message: '请填写身份证号' },
            { validator, message: '身份证号码格式错误！' },
          ]"
        />
      </van-cell-group>

      <van-cell-group>
        <van-field
          v-model="height"
          border
          size="large"
          type="digit"
          required
          label="身高"
          placeholder="身高（cm）"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="weight"
          border
          size="large"
          type="digit"
          required
          label="体重"
          placeholder="体重（kg）"
          :rules="[{ required: true }]"
        />
      </van-cell-group>

      <van-cell-group>
        <van-field
          v-model="stu_address"
          is-link
          readonly
          label="地区"
          size="large"
          required
          placeholder="请选择所在地区"
          :rules="[{ required: true }]"
          @click="show = true"
        />
        <van-field
          v-model="finish_school_name"
          is-link
          readonly
          label="学校"
          size="large"
          required
          placeholder="请选择学校"
          :rules="[{ required: true }]"
          @click="schoolshow = true"
        />
        <van-field
          v-model="classes"
          label="班级"
          size="large"
          required
          placeholder="输入班级"
          :rules="[{ required: true }]"
        />

        <van-field
          v-model="last_score"
          border
          size="large"
          type="text"
          required
          label="近期总成绩"
          placeholder="请输入近期总成绩"
          :rules="[{ required: true }]"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="fname"
          border
          size="large"
          type="text"
          required
          label="监护人姓名"
          placeholder="请输入监护人姓名"
          :rules="[{ required: true }]"
        />
        <van-field
          v-model="ftel"
          border
          size="large"
          type="tel"
          required
          label="监护人电话"
          placeholder="请输入电话"
          :rules="[
            { required: true },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' },
          ]"
        />
      </van-cell-group>

      <div style="margin: 0.32rem">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <!-- 选择地区 -->
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        @close="show = false"
        @finish="onFinish"
      />
    </van-popup>
    <!-- 选择学校 -->
    <van-action-sheet v-model="schoolshow">
      <div class="content">
        <van-picker
          show-toolbar
          :columns="columns"
          @cancel="onCancel"
          @confirm="onConfirm"
        />
      </div>
    </van-action-sheet>
    <!-- 输入学校 -->
    <van-popup
      v-model="isElseShow"
      round
      :style="{
        height: '31%',
        width: '90%',
        padding: '.5rem',
        boxSizing: 'border-box',
      }"
      @click-overlay="closeSchoolShow"
    >
      <van-field
        v-model="finish_school_name"
        label="学校"
        size="large"
        required
        border
        placeholder="请输入学校名称"
      />
      <van-button
        type="info"
        @click="closeSchoolShow"
        round
        block
        style="margin-top: 1rem"
        >确定</van-button
      >
    </van-popup>
  </div>
</template>

<script>
import { getCityInfo, getSchoolInfo, submitStuInfo } from "@/api/report.js";
export default {
  name: "ReportIndex",
  data() {
    return {
      name: "",
      gender: "",
      minzu: "",
      id_card: "",
      height: "",
      weight: "",
      stu_address: "",
      last_score: "",
      finish_school_id: "",
      classes: "",
      fname: "",
      ftel: "",
      show: false,
      schoolshow: false,
      cascaderValue: "",
      region: [],
      finish_school_name: "",
      // 城市列表
      options: [],
      //学校列表
      columns: [],
      //其他学校弹出层
      isElseShow: false,
    };
  },
  mounted() {
    this.getCityInfo();
  },
  methods: {
    /**
     * 身份证验证
     */
    validator(val) {
      const card15 =
        /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$/;
      const card18 =
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      return card15.test(val) || card18.test(val);
    },
    /* 获取城市信息 */
    getCityInfo() {
      let info = {
        is_region: "1",
      };
      let data = {
        request: JSON.stringify(info),
      };
      getCityInfo(data).then((res) => {
        this.options = res.data.region_list;
        let data = res.data;
        if (data.region.length > 0) {
          this.stu_address = `${data.province_cn}/${data.city_cn}/${data.district_cn}`;
        }
        this.name = data.name;
        this.gender = String(data.gender);
        this.minzu = data.minzu;
        this.id_card = data.id_card;
        this.height = data.height;
        this.weight = data.weight;

        this.finish_school_id = data.finish_school_id;
        this.finish_school_name = data.finish_school;
        this.classes = data.classes;
        this.region = data.region;
        this.fname = data.fname;
        this.ftel = data.ftel;
        this.last_score = data.last_score;
        this.student_id = JSON.parse(localStorage.getItem("header")).student_id;
        this.project_id = JSON.parse(
          localStorage.getItem("projectInfo")
        ).project_id;
        this.status = "2";
      });
    },
    /* 选择城市 */
    onFinish({ selectedOptions }) {
      this.show = false;
      this.stu_address = selectedOptions.map((option) => option.text).join("/");
      let region = [];
      selectedOptions.map((item, index) => {
        region[index] = { name: item.text, value: item.value };
      });
      this.region = region;
      let info = {
        city_id: selectedOptions[1].value,
        district_id: selectedOptions[2].value,
      };
      this.getSchoolInfo(info);
    },
    /* 获取学校信息 */
    getSchoolInfo(info) {
      let data = {
        request: JSON.stringify(info),
      };
      getSchoolInfo(data).then((res) => {
        let data = [...res.data];
        data.forEach((item) => {
          item.text = item.finish_school_name;
        });
        this.columns = res.data;
      });
    },
    /* 选择学校 */
    onConfirm(value, index) {
      console.log("value", value, index);
      if (value.finish_school_name == "其他") {
        this.finish_school_id = value["id"];
        this.isElseShow = true;
      } else {
        this.finish_school_name = value["finish_school_name"];
        this.finish_school_id = value["id"];
      }
      this.schoolshow = false;
    },
    onCancel() {
      this.schoolshow = false;
    },
    closeSchoolShow() {
      this.isElseShow = false;
      console.log(this.isElseShow);
    },
    /* 提交信息 */
    toReviewPage(values) {
      let info = {
        name: this.name,
        gender: this.gender,
        minzu: this.minzu,
        id_card: this.id_card,
        height: this.height,
        weight: this.weight,
        finish_school_id: this.finish_school_id,
        finish_school_name: this.finish_school_name,
        classes: this.classes,
        region: this.region,
        fname: this.fname,
        ftel: this.ftel,
        last_score: this.last_score,
        student_id: JSON.parse(localStorage.getItem("header")).student_id,
        project_id: JSON.parse(localStorage.getItem("projectInfo")).project_id,
        status: "2",
      };
      console.log("提交", values);
      let data = {
        request: JSON.stringify(info),
      };
      submitStuInfo(data).then((res) => {
        console.log("提交成功", res);
        if (res.code == 0) {
          this.$router.push("/review");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.img-box {
  width: 100%;
  height: 0.88rem;
  overflow: hidden;
  img {
    width: 100%;
    /*  height: 100%; */
  }
}
.sex-box {
  width: 100%;
  padding: 0.24rem 0.32rem;
  display: flex;
  font-size: 0.32rem;
  box-sizing: border-box;
  p {
    width: 2rem;
    flex: 0 0 2rem;
  }
  .van-radio-group {
    flex: 1;
    display: flex;
    align-items: center;
    .van-radio {
      margin-right: 0.5rem;
    }
  }
}
.van-button {
  margin-top: 0.22rem;
}
</style>

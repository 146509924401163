<template>
  <div>
    <div class="back" @click="backClick">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">选择学校</div>
    </div>
    <div class="title">请选择需要管理的学校:</div>
    <div
      v-for="(item, index) in schoolData"
      :key="index"
      class="school"
      @click="schoolSelect(item.school_id)"
    >
      {{ item.school_name }}
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { teacherLogin } from "@/api/xinhua/project_list.js";

export default {
  data() {
    return {
      schoolData: [],
      mobile: "",
      code: "",
    };
  },
  mounted() {
    this.schoolData = JSON.parse(localStorage.getItem("schoolList"));
    (this.mobile = this.$route.query.mobile),
      (this.code = this.$route.query.code);
  },
  methods: {
    schoolSelect(id) {
      let info = {
        mobile: this.mobile,
        code: this.code,
        school_id: id,
      };
      const data = {
        request: JSON.stringify(info),
      };
      teacherLogin(data).then((res) => {
        (res, "提交");
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        localStorage.setItem("teacherInfo", JSON.stringify(res.data));
        this.$router.push("/projectList");
      });
    },
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image {
  width: 20px;
  height: 20px;
  margin-left: 0.2rem;
}
.back_txt {
  margin: 0 35%;
  text-align: center;
}
.title {
  color: #a7a7a7;
  font-size: 16px;
  padding: 20px;
}
.school {
  background: #fff;
  margin: 20px;
  padding: 15px;
}
</style>
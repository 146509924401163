<template>
  <div>
    <div class="back" @click="backClick">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">添加信息</div>
    </div>
    <div style="background: #fff; padding-bottom: 0.5rem；">
      <div class="title">当前学生信息</div>
      <div class="school_box">
        <div class="sex_box student">
          <div class="student">{{ infoList.name }}</div>
          <div class="student">{{ infoList.gender == 1 ? "男" : "女" }}</div>
          <div class="student">{{ infoList.mobile }}</div>
        </div>
        <div class="sex_box">
          <div class="txt">{{ infoList.finish_school }}</div>
          <div class="txt">{{ infoList.last_score }}</div>
        </div>
      </div>
    </div>
    <div style="background: #fff; margin: 0.2rem auto">
      <div class="stu_info">
        更新学生的主绑定人 <span class="span">{{ primary_name }}</span> 为
        <span class="span">{{ user_name }}老师</span>，根据学校要 求
        请输入更换原因：
      </div>
      <van-field
        class="remark"
        v-model="reason"
        autosize
        type="textarea"
        placeholder="请输入更换原因"
        show-word-limit
      />
      <div class="upload">上传照片:</div>
      <van-uploader
        style="padding-left: 0.4rem"
        v-model="fileList"
        :after-read="afterRead"
      />
      <div class="btn" @click="submitClick">提交</div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import { studentAdd, uploadImgClick } from "@/api/xinhua/project_list.js";
export default {
  data() {
    return {
      infoList: "",
      primary_name: "",
      user_name: "",
      reason: "",
      fileList: [],
      imgUrl: "",
    };
  },
  mounted() {
    this.infoList = this.$route.query.info;
    this.primary_name = this.$route.query.primary_name;
    this.user_name = this.$route.query.user_name;
  },
  methods: {
    afterRead({ file }) {
      const data = new FormData();
      data.append("file", file);
      uploadImgClick(data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        this.imgUrl = res.data;
      });
    },
    submitClick() {
      if (this.reason == "") {
        Toast("请输入更换原因");
        return;
      }
      if (this.imgUrl == "") {
        Toast("请上传图片");
        return;
      }
      let headers = {
        token: JSON.parse(localStorage.getItem("teacherInfo")).token,
      };
      let val = {
        project_id: this.infoList.project_id,
        finish_school: this.infoList.finish_school,
        name: this.infoList.name,
        gender: this.infoList.gender,
        mobile: this.infoList.mobile,
        last_score: this.infoList.last_score,
        id_card: this.infoList.id_card,
        force_open: 1,
        image_url: this.imgUrl,
        reason: this.reason,
      };
      const data = {
        request: JSON.stringify(val),
      };
      studentAdd(headers, data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        Dialog.alert({
          message: "提交成功",
        }).then((res) => {
          this.$router.go(-2);
          console.log(res, "提交");
        });
      });
    },
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image {
  width: 20px;
  height: 20px;
  margin-left: 0.2rem;
}
.back_txt {
  margin: 0 35%;
  text-align: center;
}
.title {
  color: #a7a7a7;
  font-size: 16px;
  padding: 20px;
}
.school_box {
  width: 335px;
  border-radius: 10px;
  background: #fff;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 1px 9px #b1c6ff52;
}
.sex_box {
  display: flex;
  align-items: center;
  margin: 0 0.28rem;
  border-bottom: 0.3px solid #eeee;
  padding: 0.28rem 0;
}
.student {
  font-weight: bold;
  color: #161616;
  font-size: 16px;
}
.txt {
  font-size: 12px;
  text-align: center;
  color: #8c8c8c;
}
.sex_box div {
  margin: 0 0.2rem;
}
.span {
  color: #4581fc;
}
.stu_info {
  padding: 0.4rem;
  color: #7e7e7e;
  text-align: left;
  font-size: 14px;
  line-height: 0.5rem;
}
.remark {
  border-radius: 10px;
  background: #fafafa;
  width: 335px;
  height: 188px;
  margin: 0 auto;
}
.upload {
  font-size: 14px;
  text-align: left;
  color: #7e7e7e;
  padding: 0.4rem;
}
.btn {
  width: 339px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: #4581fc;
  margin: 0.5rem auto;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
</style>
<template>
  <div class="outer-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="info-box">
      <!-- <div>合计：{{ stuInfo.total_amount }}</div> -->
      <!-- <div class="wrap-box">
        <div>{{ stuInfo.text }}</div>
        <div>{{ stuInfo.update_date }}</div>
      </div> -->
      <div class="money-box">
        <div class="pay-img">
          <div class="img-box">
            <img src="../../../assets/img/topay.png" />
          </div>
          <span :class="stuInfo.text == '拒绝' ? 'span1' : 'span'">{{
            stuInfo.text
          }}</span>
        </div>
        <!-- <span v-if="stuInfo.total_amount * 1 > 0"
          >{{ stuInfo.total_amount }}元</span
        > -->
      </div>
      <div class="remark" v-if="stuInfo.status == 10">
        {{ stuInfo.remark }}
      </div>

      <div class="sub-info">
        <div class="report-btn">
          <van-button
            type="info"
            block
            round
            @click="resetInfo"
            v-if="stuInfo.status == 10"
            >重新填报</van-button
          >
        </div>

        <van-cell-group
          inset
          v-for="item in stuInfo.submit_data"
          :key="item.field"
        >
          <van-field
            name="uploader"
            :label="item.title"
            v-if="item.type == 'upload'"
            class="img-field"
          >
            <template #input>
              <van-uploader
                v-model="item.value"
                disabled
                :show-upload="false"
                :deletable="false"
                :max-count="2"
                input-align="right"
              />
            </template>
          </van-field>
          <van-field
            border
            readonly
            rows="2"
            autosize
            type="textarea"
            input-align="right"
            :value="item.value"
            :label="item.title"
            v-else
          />
          <!-- <van-field
            border
            readonly
            input-align="right"
            :value="item.value"
            :label="item.title"
            v-else
          /> -->
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/standard/onlyLogin.js";
// import { Toast } from "vant";
export default {
  name: "AuditIndex",
  data() {
    return {
      headerInfo: {},
      stuInfo: "",
      timeFlag: false,
      projectInfo: "",
      timeTemp: null,
    };
  },
  mounted() {
    let headerInfo = localStorage.getItem("headerInfo");
    console.log("headerInfo", headerInfo);
    let projectInfo = localStorage.getItem("projectInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);

    this.timeFlag = true;
    this.getUserInfo();
  },
  methods: {
    /* 获取用户信息 */
    timer() {
      if (this.timeFlag) {
        this.timeTemp = setTimeout(() => {
          this.getUserInfo();
        }, 3000);
      } else {
        clearTimeout(this.timeTemp);
      }
    },
    /* 重新填报 */
    resetInfo() {
      console.log("重新填报");
      this.$router.push("/onlyLoginForm");
    },
    /* 获取用户信息 */
    getUserInfo() {
      console.log("this.headerInfo", this.headerInfo.token);
      let headers = {
        token: this.headerInfo.token,
      };
      getUserInfo(headers).then((res) => {
        console.log("获取用户信息", res);
        if (res.code == 0) {
          // this.stuInfo = res.data;
          res.data.submit_data.forEach((item) => {
            if (item.type == "upload" && item.value.length) {
              let data = [];
              item.value.map((el, index) => {
                data[index] = { url: el };
              });
              item.value = data;
            }
          });
          this.stuInfo = res.data;
          console.log("=====", res.data.submit_data);
          if (res.data.status == 2 || res.data.status == 6) {
            this.sutdentData = res.data;
            this.timer();
          } else if (res.data.status == 3 || res.data.status == 5) {
            this.timeFlag = false;
            this.$router.push("/standardTopay");
          } else if (res.data.status == 4) {
            this.timeFlag = false;
            this.$router.push("/readyPay");
          } else if (res.data.status == 1) {
            this.timeFlag = false;
            this.$router.push("/onlyLoginForm");
          } else if (res.data.status == 10) {
            this.timeFlag = false;
            // Toast(res.data.remark);
          }
        }
      });
    },
  },
  destoryed() {
    this.timeFlag = false;
    clearTimeout(this.timeTemp);
  },
};
</script>

<style lang="less" scoped>
.img-field {
  /deep/ .van-field__control--custom {
    justify-content: flex-end !important;
  }
}
.sub-info {
  width: 100%;
}
.van-cell-group--inset {
  margin: 0;
  overflow: hidden;
  border-radius: 8px;
}
.van-cell {
  padding: 10px 10px;
}
/deep/ .van-field__label {
  width: 7em !important;
}
.outer-box {
  width: 100%;
  height: 100%;
  background: #fff;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-box {
    background: #fff;
    padding: 0.2rem 0.2rem;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 0.4rem;
    box-sizing: border-box;
    .report-btn {
      padding: 0 0.32rem;
    }
    .wrap-box {
      div {
        padding: 0.2rem 0;
        text-align: center;
      }
    }
    .remark {
      font-size: 0.3rem;
      margin: 0.13rem 0 0.26rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: red;
      width: 90%;
      text-align: center;
    }
  }
  .sub-box {
    padding: 0.2rem 0.2rem;
  }
  .btn-box {
    margin-top: 1rem;
    padding: 0 0.2rem;
  }
  .pay-img {
    width: 100%;
    margin-top: 0.66rem;
    font-size: 0.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .img-box {
      width: 0.88rem;
      /* height: 1.06rem; */
      img {
        width: 100%;
        height: 100%;
      }
    }
    .span {
      margin: 0.2rem 0;
      font-weight: bold;
      color: #4087f0;
    }
    .span1 {
      margin: 0.2rem 0;
      font-weight: bold;
      color: red;
    }
  }
}
</style>

import request from '@/utils/request.js'
// 老师登录
const teacherLogin = (data) => {
	return request({
		url: '/teacher.xinhua.login',
		method: 'post',
		data: data
	})
}
//获取验证码
const getProjectCode = (data) => {
	return request({
		url: '/teacher.xinhua.sendingSms',
		method: 'post',
		data: data
	})
}

//项目列表
const getProjectData = (headers, data) => {
	return request({
		url: '/project.xinhua.project_list',
		method: 'post',
		headers,
		data: data
	})
}
//学生列表 
const studentData = (headers, data) => {
	return request({
		url: '/project.xinhua.student_list',
		method: 'post',
		headers,
		data: data
	})
}
//添加学生信息 
const studentAdd = (headers, data) => {
	return request({
		url: '/teacher.xinhua.addstudent',
		method: 'post',
		headers,
		data: data
	})
}
//开关 
const switchClick = (headers, data) => {
	return request({
		url: '/project.xinhua.studentswitch',
		method: 'post',
		headers,
		data: data
	})
}
//上传图片 
const uploadImgClick = (data) => {
	return request({
		url: '/h5.index.student.uploadfile',
		method: 'post',
		data: data
	})
}


export {
	getProjectData,
	teacherLogin,
	getProjectCode,
	studentData,
	studentAdd,
	switchClick,
	uploadImgClick
}
<template>
    <div class="container">
        <div class="isUsed" v-if="dataInfo.is_used == 1">
            <div class="firstModel" v-if="dataInfo.show_type == 0">
                <div class="banner">
                    <el-image
                        style="width: 100%; height: 100%"
                        :src="dataInfo.content.banner"
                        fit="scale-down"></el-image>
                </div>
                <div class="content">
                    <div class="item" v-for="(item,index) in dataInfo.content.list" :key="index">
                        <div class="title">{{ item.info }}</div>
                        <div class="imgNode">
                            <el-image
                                :src="item.qrCodeUrl"
                                fit="scale-down"></el-image>
                        </div>
                    </div>
                </div>
            </div>
            <div class="secondModel" v-if="dataInfo.show_type == 1">
                <div class="banner">
                    <el-image
                        style="width: 100%; height: 100%"
                        :src="dataInfo.content.banner"
                        fit="scale-down"></el-image>
                </div>
                <div class="content">
                    <div class="item" v-for="(item,index) in dataInfo.content.list" :key="index">
                        <div class="img"></div>
                        <div class="info">{{ item.info }}</div>
                        <div class="btn"><el-button size="mini" type="primary" @click="pushRoute(item.addressUrl)"> {{ item.name }} </el-button></div>
                    </div>
                </div>
            </div>
        </div>
        <div  class="noUsed" v-else>
            请修改状态为打开
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { getToolsLinkAPI } from '@/api/link.js'
export default {
  name: "Link",
  data(){
    return {
        dataInfo: {}
    };
  },
  mounted() {
    this.getDataByHashValue()
  },
  methods:{
    async getDataByHashValue(){
        let formData = new FormData()
        formData.append('request',JSON.stringify({
            hash: this.$route.params.hash
        }))
        let res = await getToolsLinkAPI(formData)
        if(res.code != 0){
            Toast(res.msg)
            return
        }

        this.dataInfo = res.data.info

        if(this.dataInfo.show_type == 2){
            location.href = this.dataInfo.content.addressLink
        }

        document.title = this.dataInfo.title

    },
    pushRoute(url){
        location.href = url
        // console.log(url)
        // console.log(location.href)
    },
  }
};
</script>

<style lang="less" scoped>
.container{
    width: 100%;
    height: 100%;

    .isUsed{
        width: 100%;
        height: 100%;

        .firstModel {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .banner{
                width: 100%;
                height: auto;
            }

            .content{
                width: 100%;
                flex: 1;
                padding: 20px 10px;
                box-sizing: border-box;

                .item{
                    background-color: #fff;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    box-shadow: 2px 2px 1px 1px #ccc;

                    .title{
                        margin-bottom: 10px;
                        padding: 5px;
                        font-size: 16px;
                        border-bottom: rgb(242, 242, 242) 1px solid;
                    }
                    .imgNode{
                        width: 100%;
                        height: 200px;

                        .el-image{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .secondModel {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .banner{
                width: 100%;
                height: auto;
            }

            
            .content{
                width: 100%;
                flex: 1;
                padding: 20px 10px;
                box-sizing: border-box;

                .item{
                    height: 40px;
                    background-color: #fff;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    box-shadow: 2px 2px 1px 1px #ccc;
                    padding: 10px 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .img{
                        width: 20px;
                        height: 20px;
                        background: url('../../assets/img/icon.png') no-repeat;
                        margin-right: 5px;
                    }

                    .btn{
                        .el-button{
                            height: 30px;
                            border-radius: 30px;
                        }
                    }

                    .info{
                        width: 60%;
                        word-wrap: break-word;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .noUsed{
        width: 100%;
        height: 100%;
        font-size: 18px;
        text-align: center;
        line-height: 400px;
    }
}
</style>
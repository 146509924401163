import request from '@/utils/request.js'
/* 获取项目信息接口 */
const getProjectInfo = (data) => {
    return request({
        url: '/h5.index.project.info',
        method: 'post',
        data: data
    })
}
/* 登录 */
const getLogin = (data) => {
    return request({
        url: '/h5.index.project.login',
        method: 'post',
        data
        //Body 参数使用data设置
        //Query参数使用params设置
        //Header 参数使用headers设置
    })
}
/* 获取验证码 */
const getSMSCode = (data => {
    return request({
        url: '/h5.index.project.sendSms',
        method: 'post',
        data
    })
})
/* 注册 */
const getRegister = (data => {
    return request({
        url: '/h5.index.project.register',
        method: 'post',
        data
    })
})
/* 重置密码 */
const getReset = data => {
    return request({
        url: '/h5.index.project.resetpasswd',
        method: 'post',
        data
    })
}
/* 教师登录 */
const getTeacherLogin = (data) => {
    return request({
        url: '/h5.index.teacher.login',
        method: 'post',
        data
    })
}
/* 登录页面获取项目信息 */
const loginInfo = (data) => {
    return request({
        url: '/h5.index.project.info',
        method: 'post',
        data
    })
}
/* 登录页面查询账单 */
const lookforBills = (data) => {
    return request({
        url: '/h5.index.project.h5login',
        method: 'post',
        data
    })
}
/* 获取openID */
const openID = (data) => {
    return request({
        url: '/h5.index.project.openid',
        method: 'post',
        data
    })
}
export {
    getProjectInfo,
    getLogin,
    getSMSCode,
    getRegister,
    getTeacherLogin,
    getReset,
    loginInfo,
    lookforBills,
    openID
}
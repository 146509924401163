<template>
  <div class="projectLogin">
    <div class="login_beg" v-if="!isOpen">
      <div class="count" >
        <img src="../../assets/img/icon_head.png" alt="" />
        <div class="count_title">学生信息录入系统</div>
        <van-field
          class="int"
          type="number"
          v-model="phone_data"
          label="手机号"
          placeholder="请输入手机号"
          maxlength="11"
        />
        <van-field
          class="int"
          v-model="code_data"
          label="验证码"
          placeholder="请输入验证码"
        >
          <template #button>
            <div class="txt" v-if="codeType" @click="projectCodeClick">
              获取验证码
            </div>
            <div class="txt" v-else>{{ count }}s</div>
          </template>
        </van-field>
        <div class="btn" @click="loginClick">登录</div>
      </div>
    </div>
    <div class="textInfo" v-if="isOpen">
        当前项目需备案升级
      </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { teacherLogin, getProjectCode } from "@/api/xinhua/project_list.js";
export default {
  data() {
    return {
      phone_data: "",
      count: 60,
      codeType: true,
      timer: "",
      code_data: "",
      isOpen:true,
    };
  },
  mounted() {},
  methods: {
    //获取验证码
    projectCodeClick() {
      if (this.phone_data == "") {
        Toast("请输入电话号码");
        return;
      } else {
        const reg_mobile = /^1[3456789]\d{9}$/;
        if (!reg_mobile.test(this.phone_data)) {
          Toast("请输入有效的电话号码");
          return;
        }
      }
      let info = {
        mobile: this.phone_data,
      };
      const data = {
        request: JSON.stringify(info),
      };
      getProjectCode(data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        this.codeType = false;
        this.setTimer();
      });
    },
    //秒数
    setTimer() {
      this.timer = setInterval(() => {
        if (this.count < 1) {
          this.count = 60;
          this.codeType = true;
          clearInterval(this.timer); // 清空定时器
        }
        var count_s = this.count - 1;
        this.count = count_s;
      }, 1000);
    },
    //提交
    loginClick() {
      if (this.phone_data == "") {
        Toast("请输入电话号码");
        return;
      } else {
        const reg_mobile = /^1[3456789]\d{9}$/;
        if (!reg_mobile.test(this.phone_data)) {
          Toast("请输入有效的电话号码");
          return;
        }
      }
      if (this.code_data == "") {
        Toast("请输入验证码");
        return;
      }
      let info = {
        mobile: this.phone_data,
        code: this.code_data,
      };
      const data = {
        request: JSON.stringify(info),
      };
      teacherLogin(data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        localStorage.setItem("schoolList", JSON.stringify(res.data));
        this.$router.push({
          path: "/schoolSelect",
          query: {
            mobile: this.phone_data,
            code: this.code_data,
          },
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
// .login_beg {
//   width: 100%;
//   height: 1624px;
//   background-image: url("../../assets/img/login_img.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat;
// }

.projectLogin{
  height: 100%;
}
.textInfo{
  height: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 400px;
  background-color: #fff;
}
.count {
  background: #fff;
  margin: 2rem 0.3rem;
  text-align: center;
  border-radius: 11px;
  box-shadow: 0 3px 6px #d5e9ff66;
  padding: 20px 0;
}
.count img {
  margin-top: -0.8rem;
}
.count_title {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  color: #283f65;
  padding: 20px 0;
}
.btn {
  width: 300px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: #4581fc;
  margin: 0.5rem auto;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
.txt {
  color: #478aff;
}
.int {
  border-radius: 22.5px;
  background: #f6faff;
  width: 300px;
  margin: 16px auto;
}
</style>
import request from '@/utils/request_base.js'
/* 非登录表单 */
const nologinForm = (data) => {
	return request({
		url: '/h5.index.project.notemplate',
		method: 'post',
		data: data
	})
}
const submitForm = (data) => {
	return request({
		url: '/h5.order.standinfo',
		method: 'post',
		data: data
	})
}
export {
	nologinForm,
	submitForm
}
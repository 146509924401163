<template>
  <div class="outer-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="info-box">
      <div class="money-box">
        <div class="pay-img">
          <div class="img-box">
            <img src="../../../assets/img/readypay.png" />
          </div>
          <span>{{ stuInfo.text }}</span>
        </div>
        <span v-if="stuInfo.total_amount * 1 > 0"
          >{{ stuInfo.total_amount }}元</span
        >
      </div>
      <span>{{ stuInfo.update_date }}</span>
      <div  style="margin:20px auto;text-align:center;font-size:20px;font-weight: bold;padding-left:20px;" v-if="stuInfo.complate_tip!=''">{{stuInfo.complate_tip}}</div>
    </div>
    <!-- <div class="sub-box" v-if="stuInfo.advince_status == 1">
      <van-button type="info" round block @click="toNotification">{{
        stuInfo.download_text || "下载通知书"
      }}</van-button>
    </div> -->
    <div class="btn-box">
      <div class="sub-box" v-if="stuInfo.advince_status == 1">
        <van-button type="info" round block @click="toNotification">{{
          stuInfo.download_text || "下载通知书"
        }}</van-button>
      </div>
      <div class="sub-box" v-if="stuInfo.jump_url">
        <van-button type="primary" round block @click="continueDoing">{{
          stuInfo.download_text1 || "继续操作"
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo } from "@/api/standard/onlyLogin.js";
export default {
  name: "ReadyPayIndex",
  data() {
    return {
      stuInfo: "",
      projectInfo: "",
    };
  },
  mounted() {
    let projectInfo = localStorage.getItem("projectInfo");
    let headerInfo = localStorage.getItem("headerInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);
    this.getUserInfo();
  },
  methods: {
    /* 获取用户信息 */
    getUserInfo() {
      let headers = {
        token: this.headerInfo.token,
      };
      getUserInfo(headers).then((res) => {
        console.log("获取用户信息", res);
        if (res.code == 0) {
          this.stuInfo = res.data;
        }
      });
    },
    /* 下载通知书 */
    toNotification() {
      this.$router.push("/notificationImage");
    },
    /* 继续操作 */
    continueDoing() {
      location.href = this.stuInfo.jump_url;
    },
  },
};
</script>

<style lang="less" scoped>
.outer-box {
  width: 100%;
  height: 100%;
  background: #fff;
  .banner-box {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info-box {
    padding: 0.2rem 0.2rem;
    background: #fff;
    padding: 0.2rem 0.2rem;
    border-radius: 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 0.6rem;
    box-sizing: border-box;
    .money-box {
      > span {
        font-size: 0.42rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
      }
    }
    > span {
      font-size: 0.26rem;
      margin-top: 0.26rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }
  }
  .btn-box {
    margin-top: 1rem;
  }
  .sub-box {
    padding: 0.2rem 0.2rem;
  }
}
.pay-img {
  width: 100%;
  margin-top: 0.66rem;
  font-size: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .img-box {
    width: 0.88rem;
    height: 1.06rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  span {
    margin: 0.4rem 0;
    font-weight: bold;
    color: #4087f0;
  }
}
</style>

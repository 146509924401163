import request from "@/utils/request.js";

const get_userInfo = (data) => {
  return request({
    url: "/h5.dingtalk.bind.info",
    method: "post",
    data: data,
  });
};

const bind_user = (data) => {
  return request({
    url: "/h5.dingtalk.bind",
    method: "post",
    data: data,
  });
};
const get_code = (data) => {
  return request({
    url: "/h5.dingtalk.send.code",
    method: "post",
    data: data,
  });
};
const check_code = (data) => {
  return request({
    url: "/h5.dingtalk.check.code",
    method: "post",
    data: data,
  });
};

export { get_userInfo, bind_user, get_code, check_code };

<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="form-box">
      <!-- 无注册表单 -->
      <div class="custom-box">
        <form-create v-model="fapi" :rule="rule" :option="option"></form-create>
      </div>
      <!-- 缴费选择 -->
      <div class="cost-box" v-if="formInfo.verify_type == 1">
        <div v-for="(item, index) in item_json" :key="index" style="margin-bottom: 22px">
          <div v-if="item.mast_status == 3">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-input v-model="item.value" placeholder="请输入区间金额" @input="totalSum">
                <template slot="append">元</template>
              </el-input>
            </div>
            <div class="remark-box">
              区间：{{ item.options[0].value[0] }}~{{
                item.options[0].value[1]
              }}元
            </div>
          </div>
          <div v-if="item.mast_status == 2">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-select v-model="item.value" placeholder="请选择缴费信息" @change="totalSum">
                <el-option v-for="itemfee in item.options" :key="itemfee.value"
                  :label="itemfee.remark + '：' + itemfee.value + '元'" :value="itemfee.value">
                  <span style="float: left">{{ itemfee.remark }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">
                    {{ itemfee.value }}元
                  </span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="item.mast_status == 1">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-input :value="item.options[0]['value'] + '元'" disabled></el-input>
            </div>
          </div>
        </div>
      </div>
      <div class="cost-box" v-if="formInfo.verify_type == 4">
        <div class="checkCode">
          <el-form label-position="top" label-width="80px" :model="checkCodeForm" :rules="checkCodeRules">
            <el-form-item label="信息码" prop="codeValue">
              <el-row>
                  <el-col :span="20">
                    <el-input v-model="checkCodeForm.codeValue" placeholder="请输入信息码进行查询"></el-input>
                  </el-col>
                  <el-col :span="2" style="margin-left: 10px;">
                    <el-button type="text" @click="checkCodeFn">查询</el-button>
                  </el-col>
              </el-row>
              <el-row>
                <div style="color: red;" v-if="errorMes">{{ errorMes }}</div>
              </el-row>
              <el-row>
                <div style="font-size: 16px;margin-top: 5px; line-height: 20px;" v-for="item in getCurrentCodeInfo" :key="item.id">
                  {{ item.fee_item_name }} : {{ item.fee }} 元
                </div>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="sub-box">
        <!-- <div>
          <van-button type="primary" round block @click="onSave">暂存信息</van-button>
        </div> -->
        <div>
          <van-button type="info" round block @click="onSubmit">
            提交{{
              formInfo.verify_type == 1 && total_amount != 0
              ? total_amount + '元'
              : ''
            }}
          </van-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import OSS from 'ali-oss';
const client = new OSS({
  region: 'oss-cn-beijing', // 地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
  accessKeyId: 'LTAI4GEeU3VjmHAWP7GMvhAg', // 阿里云产品的通用id
  accessKeySecret: 'URUiu1qeGwBcD6D8Tzd70RFJ0Mrx5s', // 密钥
  bucket: 'fangaocloud', // OSS 存储区域名
});
var moment = require('moment'); // require
let oss_date = moment().format('YYYY-MM-DD');

import {
  onlyLoginForm,
  submitForm,
  saveForm,
} from '@/api/standard/onlyLogin.js'
// import wx from "weixin-js-sdk";
import { Toast } from 'vant'
import city from '@/utils/city.js'
import { checkCodeAPI } from '@/api/report.js'
export default {
  name: 'OnlyLoginFormIndex',
  data () {
    return {
      fapi: null,
      rule: [],
      option: {},
      formInfo: {},
      projectInfo: '',
      item_json: [
        {
          value: '',
          options: {
            value: ['', ''],
          },
        },
      ],
      payment_type_ext: localStorage.getItem('payment_type_ext'), //运行环境
      code: '0714NHkl2vAJd94pTpnl2KYFOA14NHkq',
      paydata: '',
      selectFee: '',
      total_amount: 0,

      checkCodeForm:{
        codeValue:"",
      },

      checkCodeRules:{
        codeValue:[
          { required : true, message:"信息码不能为空", trigger:'blur' }
        ]
      },

      // 当前录取码查询到的信息
      getCurrentCodeInfo:[],
      // 错误信息
      errorMes:""
    }
  },
  mounted () {
    let projectInfo = JSON.parse(localStorage.getItem('projectInfo'))
    let headerInfo = JSON.parse(localStorage.getItem('headerInfo'))
    let payment_type_ext = localStorage.getItem('payment_type_ext')
    this.payment_type_ext = payment_type_ext ? payment_type_ext : "alipay"
    this.projectInfo = projectInfo
    this.headerInfo = headerInfo
    this.paydata = JSON.parse(localStorage.getItem('paydata')) || ''
    this.getFormInfo()
  },
  methods: {
    /*  */
    changeitem_name (item) {
      this.selectFee = `${item.value}-${item.remark}`
    },
    /* 请求表单信息 */
    getFormInfo () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      let info = {
        project_id: this.projectInfo.project_id,
        student_id: this.headerInfo.student_id,
      }
      let data = {
        request: JSON.stringify(info),
      }
      let headers = { token: this.headerInfo.token }
      onlyLoginForm(headers, data).then((res) => {
        Toast.clear()
        let data = res.data
        this.formInfo = data
        //项目收费规则
        let item_json = data.project_items_json
        this.item_json = item_json
        //自定义表单
        let template_json = data.template_json
        let FcDesignerRule = template_json.FcDesignerRule;
        this.rule = FcDesignerRule;
        let option = template_json.FcDesignerOptions
        console.log('自定义表单 ------------------> ',option)

        option.global = {
          // 上传组件公共配置
          upload: {
            props: {
              httpRequest: (param) => {
                console.log('httpRequest1', param);
                let fileType = param.file.name.split('.').pop();
                client.put(`fangaopayment/${oss_date}/${new Date().getTime()}_${parseInt(Math.random() * 100000000)}.${fileType}`, param.file).then((res) => {
                  param.file.url = res.url;
                  param.onSuccess(res, param.file);
                  console.log(res);
                });
              },
              onSuccess: (res, file) => {
                console.log('上传的图片123', res, file);
                file.url = res.url;
              },
              // 'custom-request': (options) => {
              //     console.log('options', options);
              // }, 
              // 上传成功回调
              // onSuccess: (response, file) => {
              //   console.log('=====', response, file)
              //   file.url = response.data
              // },
            },
          },
          cascader: {
            props: {
              options: city,
            },
          },
        }
        // this.option = template_json.FcDesignerOptions;
        console.log('自定义表单option', option)
        this.option = option
      })
    },
    // 查询录取码
    async checkCodeFn(){
      this.getCurrentCodeInfo = []
      this.errorMes = ""

      let projectInfo = JSON.parse(localStorage.getItem("projectInfo"))
      let formData = new FormData()
      formData.append('request',JSON.stringify({
        project_id:projectInfo.project_id,
        enroll_code: this.checkCodeForm.codeValue,
      }))
      let res = await checkCodeAPI(formData)
      if(res.code === 0){
        this.getCurrentCodeInfo = res.data.list
      }else{
        this.errorMes = res.msg
      }
    },
    /* 微信支付 */
    onBridgeReady (pay_conf) {
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: pay_conf.appId, //公众号ID，由商户传入
          timeStamp: pay_conf.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_conf.nonceStr, //随机串
          package: pay_conf.package,
          signType: pay_conf.signType, //微信签名方式：
          paySign: pay_conf.sign_new, //微信签名
        },
        (res) => {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            this.$router.push('/readyPay')
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          }
        }
      )
    },
       // 支付宝支付
    alipayBridgeReady(trandNo){
      if (window.AlipayJSBridge) {
          window.AlipayJSBridge.call('tradePay', {  
            tradeNO:trandNo
              // ... 支付参数 ...  
          }, function(result) {  
              // 处理支付结果 
              console.log('获取支付结果',result) 
              window.AlipayJSBridge.call('exitApp');
          });  
      } 
    },
    /* 计算 */
    totalSum () {
      let total_amount = this.item_json.reduce((pre, cur) => {
        if (cur.mast_status == '1') {
          cur.value = cur.options[0]['value']
        }
        let value = cur.value
        if (typeof value == 'undefined') {
          value = 0
        }
        if (cur.type == 2) {
          value = value * -1
        }
        return pre + value * 1
      }, 0)
      this.total_amount = total_amount
    },
    /* 暂存信息 */
    onSave () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.totalSum()
      //删除global
      let option = this.option
      option.global = {}
      let info = {
        project_id: this.projectInfo.project_id,
        student_id: this.headerInfo.student_id,
        total_amount: this.total_amount,
        pay_type: this.projectInfo.pay_type, //this.projectInfo.pay_type,
        payment_type_ext: this.payment_type_ext, //this.payment_type_ext
        is_order: this.projectInfo.verify_type == 1 ? 1 : 0, //this.formInfo.is_order
        openid: this.paydata.openid,
        project_items_json: this.item_json,
        student_submit_json: {
          FcDesignerRule: this.rule,
          FcDesignerOptions: option,
        },
      }
      let data = {
        request: JSON.stringify(info),
      }
      let headers = { token: this.headerInfo.token }
      //暂存接口
      saveForm(headers, data).then((res) => {
        Toast.clear()
        if (res.code == 0) {
          Toast('保存成功')
          this.getFormInfo()
        } else {
          Toast(res.msg)
        }
      })
    },
    /* 保存||生成待支付订单 */
    onSubmit () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      this.totalSum()
      //删除global
      let option = this.option
      option.global = {}
      let info = {
        project_id: this.projectInfo.project_id,
        student_id: this.headerInfo.student_id,
        total_amount: this.total_amount,
        pay_type: this.projectInfo.pay_type, //this.projectInfo.pay_type,
        payment_type_ext: this.payment_type_ext, //this.payment_type_ext
        is_order: this.projectInfo.verify_type == 1 ? 1 : 0, //this.formInfo.is_order
        openid: this.paydata.openid,
        project_items_json: this.item_json,
        student_submit_json: {
          FcDesignerRule: this.rule,
          FcDesignerOptions: option,
        },
        enroll_code: this.checkCodeForm.codeValue,
        localAlipay:1  // 测试
      }
      console.log('info ----> ',info)
      let data = {
        request: JSON.stringify(info),
      }
      let headers = { token: this.headerInfo.token }
      submitForm(headers, data).then((res) => {
        if (res.code != 0) {
          setTimeout(() => {
            Toast.clear()
          }, 2000)
          return false
        }
        Toast.clear()

        let pay_conf = res.data.pay_conf ? res.data.pay_conf : null
        if(pay_conf && pay_conf.qr_code){
            location.href = pay_conf.qr_code
            return
        }

        if(pay_conf && pay_conf.code_url){
            location.href = pay_conf.code_url 
            return 
          }

        if (res.data.status == 1) {
          Toast('暂存成功，等考试成绩发布后继续完善信息')
          return
        }
        if (this.total_amount == 0 &&
          this.formInfo.verify_type == 1
        ) {
          this.$router.push('/readyPay')
          return false
        }

         if (this.formInfo.verify_type == 2) {
            this.$router.push('/adit')
            return 
          }
           if (this.formInfo.verify_type == 3) {
            this.$router.push('/submitSuccess')
            return
          }

          // 当 formInfo.verify_type == 1 或 == 4 的时候执行以下代码
            if (
              this.payment_type_ext == 'alipaywap' ||
              this.projectInfo.pay_type == 'alipaywap'
            ) {
              this.$router.push('/standardTopay')
              return
            }
            //微信内支付判断
            if (
              this.payment_type_ext == 'wxpay' &&
              this.projectInfo.pay_type != 'wxpay' &&
              this.projectInfo.pay_type != 'yinsheng' &&
              this.projectInfo.pay_type != 'fuyou'
            ) {
              Toast('请使用支付宝支付 ！')
              return false
            }
            //支付宝内支付判断
            if (
              this.payment_type_ext == 'alipay' &&
              this.projectInfo.pay_type == 'wxpay'
            ) {
              Toast('请使用微信支付 ！')
              return false
            }
            //微信内正常支付
            if (
              (this.payment_type_ext == 'wxpay' && this.projectInfo.pay_type == 'wxpay') ||
              (this.projectInfo.pay_type == 'yinsheng' && this.payment_type_ext == 'wxpay') || 
              (this.projectInfo.pay_type == 'fuyou' && this.payment_type_ext == 'wxpay')
            ) {
              let pay_conf = res.data.pay_conf
              // this.weixinPay(pay_conf);
              this.onBridgeReady(pay_conf)
            }
            //支付宝内正常支付
            if (
              this.payment_type_ext == 'alipay' &&
              this.projectInfo.pay_type == 'alipay'
            ) {
              location.href = pay_conf.url
              return
            }
            if (
              this.payment_type_ext == "alipay" && this.projectInfo.pay_type != 'wxpay'
            ) {
              // location.href = pay_conf.url
              console.log('-------------------  res ',res)
              this.alipayBridgeReady(res.data.pay_conf.trade_no)
              return
            }
            // this.weixinPay(pay_conf);
      })
    },
  },
}
</script>

<style lang="less" scoped>
.el-select {
  /* width: 100%; */
  flex: 1;
}

.content-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .banner-box {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .form-box {
    width: 100%;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;

    /* 自定义表单 */
    .cost-box,
    .custom-box,
    .sub-box {
      width: 100%;
    }

    .sub-box {
      text-align: center;
    }

    .custom-box,
    .cost-box {
      background: #fff;
      padding: 0.2rem 0.2rem;
      border-radius: 0.2rem;
      box-sizing: border-box;
    }

    /* 缴费 */
    .cost-box {
      margin-top: 0.2rem;

      .input-box {
        display: flex;
        align-items: center;

        .text {
          min-width: 2rem;
        }

        .el-input {
          flex: 1;
        }
      }

      .remark-box {
        margin-top: 0.2rem;
        text-align: center;
        color: red;
      }

      .checkCode{
          .text{
            margin-bottom: 20px;
            color: #4b4b4b;
          }
          
          .input{
            display: flex;

            .el-input{
              width: 80%;
            }

            .el-button{
              flex: 1;
            }
          }
        }
    }
  }

  /deep/ .el-cascader {
    width: 100% !important;
    display: inline-block;
    position: relative;
    font-size: 14px;
    line-height: 40px;
  }

  /* 提交 */
  .sub-box {
    margin-top: 1rem;
    display: flex;

    div {
      flex: 1;
      margin: 0 2px;
    }
  }
}
</style>

<template>
  <div v-cloak>
    <div class="img-box">
      <img src="../../assets/img/huamei.jpg" alt="" />
    </div>
    <div class="wait-box">
      <van-loading type="spinner" color="#1989fa" size="25px" vertical
        >老师审核中,请耐心等待</van-loading
      >
    </div>
    <div class="content-box">
      <van-field
        :value="sutdentData.name"
        border
        size="large"
        readonly
        type="text"
        label="学生姓名"
        placeholder="请输入学生姓名"
      />
      <div class="sex-box">
        <p>性别</p>
        <van-radio-group v-model="gender" disabled>
          <van-radio name="1">男</van-radio>
          <van-radio name="2">女</van-radio>
        </van-radio-group>
      </div>
      <van-field
        :value="sutdentData.minzu"
        border
        size="large"
        readonly
        type="text"
        label="民族"
        placeholder="请输入民族"
      />
      <van-field
        :value="sutdentData.id_card"
        border
        size="large"
        readonly
        type="idcard"
        label="身份证号"
        placeholder="请输入身份证号"
      />
      <van-field
        :value="sutdentData.height"
        border
        size="large"
        readonly
        type="digit"
        label="身高"
        placeholder="身高（cm）"
      />
      <van-field
        :value="sutdentData.weight"
        border
        size="large"
        readonly
        type="digit"
        label="体重"
        placeholder="体重（kg）"
      />
      <van-field
        :value="sutdentData.finish_school"
        readonly
        label="学校"
        size="large"
        placeholder="请选择所在学校"
      />
      <van-field
        :value="sutdentData.last_score"
        border
        readonly
        size="large"
        type="digit"
        label="近期总成绩"
        placeholder="请输入近期成绩"
      />
      <van-field
        :value="sutdentData.fname"
        border
        readonly
        size="large"
        type="text"
        label="监护人姓名"
        placeholder="请输入监护人姓名"
      />
      <van-field
        :value="sutdentData.ftel"
        border
        readonly
        size="large"
        type="tel"
        label="监护人电话"
        placeholder="请输入电话"
      />
    </div>
  </div>
</template>

<script>
import { getCityInfo } from "@/api/report.js";
export default {
  name: "ReportIndex",
  data() {
    return {
      gender: "1",
      show: false,
      fieldValue: "",
      sutdentData: {},
      timeFlag: true,
      timeTamp: null,
    };
  },
  mounted() {
    this.getStuInfo();
  },
  methods: {
    /* 获取城市信息 */
    timer() {
      if (this.timeFlag) {
        this.timeTamp = setTimeout(() => {
          this.getStuInfo();
        }, 3000);
      }
    },
    getStuInfo() {
      let info = {
        is_region: "1",
      };
      let data = {
        request: JSON.stringify(info),
      };
      getCityInfo(data).then((res) => {
        console.log("学生信息", res);
        if (res.code == 0) {
          this.timer();
          if (res.data.status == 2) {
            this.sutdentData = res.data;
            this.gender = String(res.data.gender);
          } else if (res.data.status == 3) {
            this.timeFlag = false;
            this.$router.push("/topay");
            this.gender = String(res.data.gender);
          } else if (res.data.status == 1) {
            this.gender = String(res.data.gender);
            this.timeFlag = false;
            this.$router.push("/report");
          }
        }
      });
    },
  },
  destoryed() {
    clearTimeout(this.timeTamp);
  },
};
</script>

<style lang="less" scoped>
.wait-box {
  padding: 0.4rem;
}
.img-box {
  width: 100%;
  height: 0.88rem;
  overflow: hidden;
  img {
    width: 100%;
    /*  height: 100%; */
  }
}
.sex-box {
  width: 100%;
  padding: 0.24rem 0.32rem;
  display: flex;
  font-size: 0.32rem;
  box-sizing: border-box;
  p {
    width: 2rem;
    flex: 0 0 2rem;
  }
  .van-radio-group {
    flex: 1;
    display: flex;
    align-items: center;
    .van-radio {
      margin-right: 0.5rem;
    }
  }
}
.van-button {
  margin-top: 0.22rem;
}
</style>

<template>
  <div class="page">
    <div class="wroper" v-if="isShow">
      <img
        src="../../assets/img/leads.png"
        alt=""
        style="width: 90%; margin-top: 50px"
      />
    </div>
    <div class="icon">
      <img :src="logo_url" alt="">
    </div>
    <div class="school_name">{{ school_name }}</div>
    <div class="main">
      <div class="infoInput">
        <div class="box">
          <span class="label">{{ login_text1 }}：</span>
          <input
            type="text"
            v-model="infoList.name"
            class="value"
            placeholder="请输入"
          />
        </div>
        <div class="box">
          <span class="label">{{ login_text2 }}：</span>
          <input
            type="text"
            class="value"
            v-model="infoList.number"
            placeholder="请输入"
          />
        </div>
        <div class="box" style="position: relative">
          <span class="label">验证码：</span>
          <input
            type="text"
            class="value"
            v-model="infoList.image_code"
            placeholder="请输入"
          />
          <img
            :src="imgUrl"
            alt=""
            style="position: absolute; right: 0; width: 100px"
            @click="changeImg"
          />
        </div>
      </div>
      <div class="imgTag">看不清，点击图片更换</div>
      <div class="btn" @click="forLogin">查询账单</div>
      <div class="failTag">{{ login_tip }}</div>
    </div>
    <div class="phoneNum">技术服务热线：19930383437、19930381437</div>
    <div class="footer">
      <div>
        本系统由
        <span @click="pushUrl">凡高云校园</span>
        提供技术支持
      </div>
    </div>
  </div>
</template>

<script>
import { loginInfo, lookforBills, openID } from '@/api/login.js'
import { Toast } from 'vant'
import configopenid from '@/utils/wxconfig.js'

export default {
  name: '',
  props: {},
  components: {},
  data() {
    return {
      open_type: '',
      imgId: '',
      imgUrl: '',
      isShow: false,
      school_name: "",
      infoList: {
        name: '',
        number: '',
        image_id: '',
        image_code: '',
      },
      openCode: '',
      login_text1:"",
      login_text2:"",
      login_tip:'',
      logo_url:""
    }
  },
  computed: {},
  watch: {},
  created() {
    //   if (this.$route.query.code) {
    //     // localStorage.setItem('code', this.$route.query.code)
    //     this.openCode = this.$route.query.code
    //   }
  },
  mounted() {
    this.getUrlParams()
    this.getLoginInfo()
    console.log(this.$route.query.code)
    if (navigator.userAgent.indexOf('AlipayClient') > -1) {
      this.open_type = 'alipay'
      localStorage.setItem('open_type', 'alipay')
    }
    var ua = window.navigator.userAgent.toLowerCase()

      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        this.open_type = 'wxpay'
        localStorage.setItem('open_type', 'wxpay')
      }
    if (
      ua.match(/MicroMessenger/i) == 'micromessenger' &&
      localStorage.getItem('openType') == 'alipay'
    ) {
      this.isShow = true
    }
    if (this.$route.query.code) {
      localStorage.setItem('code', this.$route.query.code)
      this.getOpenID()
    }

    if (this.$route.query.auth_code) {
      localStorage.setItem('code', this.$route.query.auth_code)
      this.getOpenID()
    }

    // let code = this.$route.query.code ? this.$route.query.code : this.$route.query.auth_code;

    // if (!code) {
   
    // }
    //if()
    this.getCodeImg()
  },
  methods: {
    getUrlParams() {
      const url_string = new URLSearchParams(window.location.search)
      const url = window.location.href // window.location.href
      console.log(url_string, 'url_string')
      const hash_list = this.getQueryString(url)
      console.log(hash_list, 'hash_list')
      localStorage.setItem('project_hash', hash_list.project_hash)
    },

    getQueryString(url) {
      let pattern = /(\w+)=(\w+)/gi //定义正则表达式
      let parames = {} // 定义参数对象
      url.replace(pattern, ($, $1, $2) => {
        parames[$1] = $2
      })
      return parames
    },

    getOpenID() {
      let info = {
        open_type:
          localStorage.getItem('open_type') == 'wxpay' ? 'wx' : 'alipay',
        code: localStorage.getItem('code'),
      }
      const data = {
        request: JSON.stringify(info),
      }
      openID(data).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('openid', res.data.openid)
        } else {
          Toast(res.msg)
        }
      })
    },
    forLogin() {
      const data = {
        request: JSON.stringify(this.infoList),
      }
      lookforBills(data).then((res) => {
        if (res.code == 0) {
          localStorage.setItem('header', JSON.stringify(res.data))
          this.$router.push('/payList')
        } else {
          Toast(res.msg)
        }
      })
    },
    changeImg() {
      this.getCodeImg()
    },
    getCodeImg() {
      const randomValue = Math.floor(Math.random() * 100) + 1 // 生成1-100的随机数
      const now = new Date().getTime() // 获取当前时间戳
      this.imgId = `${randomValue}${now}` // 拼接随机数和时间戳作为图片id
      this.infoList.image_id = this.imgId
      let dommin = configopenid.isline
        ? configopenid.onlineimgurl
        : configopenid.devimgUrl
      this.imgUrl = `${dommin}/h5.index.project.sendVerifyImage?id=${this.imgId}`
    },
    getLoginInfo() {
      const info = {
        // project_hash: this.$route.query.project_hash,
        project_hash: localStorage.getItem('project_hash'),
        h5_route: 'h5login',
        open_type: this.open_type,
      }
      const data = {
        request: JSON.stringify(info),
      }
      loginInfo(data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg)
        }
        let code = this.$route.query.code ? this.$route.query.code : this.$route.query.auth_code;
        if ((this.open_type == 'wxpay' || this.open_type == 'alipay') && !code) {
          window.location.href = res.data.openurl
        }
        this.school_name = res.data.school_name
        this.login_text1 = res.data.login_text1
        this.login_text2 = res.data.login_text2
        this.login_tip=res.data.login_tip
        this.logo_url = res.data.logo == "" ? '../../assets/img/logo.png' : res.data.logo
        window.localStorage.setItem('openType', res.data.open_type)
        window.localStorage.setItem('projectInfo', JSON.stringify(res.data))

        if (this.open_type == 'wxpay' && res.data.open_type == 'alipay') {
          this.isShow = true
        }
      })
    },
    pushUrl() {
      window.location.href = 'https://m.fangao.100eks.com/#/'
    },
  },
}
</script>

<style lang="less" scoped>
.wroper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: #333333;
  opacity: 0.8;
  z-index: 999;
  text-align: center;
}
.page {
  height: 100vh;
  // background-color: #d1effd;
  background: url('../../assets/img/h5loginBG.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  padding: 0 15px;
  .icon {
    margin: 50px auto 0;
    width: 84px;
    height: 84px;
    text-align: center;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .school_name {
    color: #0d658e;
    margin-top: 10px;
    text-align: center;
    font-size: 20px;
  }
  .main {
    width: 100%;
    height: 400px;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    .infoInput {
      height: 150px;
      .box {
        height: 50px;
        display: flex;
        align-items: center;
        border-bottom: 2px solid #dde3e9;

        // .label{
        //   width: 60px;
        // }
      }
    }
    .imgTag {
      margin-top: 15px;
      font-size: 12px;
      color: #2ab1f3;
      text-align: right;
    }
    .btn {
      margin-top: 40px;
      width: 100%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      background-color: #36a7fa;
      border-radius: 22px;
      color: #fff;
    }
    .failTag {
      margin-top: 15px;
      text-align: center;
      color: red;
      font-size: 12px;
    }
    .helpTag {
      margin-top: 15px;
      color: #333333;
      font-size: 12px;
      text-align: right;
    }
  }
  .phoneNum {
    margin-top: 20px;
    text-align: center;
  }
  .footer {
    width: 100%;
    position: fixed;
    bottom: 10px;
    color: #0d658e;
    display: flex;
    justify-content: center;
    span {
      color: rgb(255, 154, 0);
    }
  }
}
</style>

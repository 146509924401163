<template>
  <div class="text-box">
    <!-- 项目信息 : {{ projectInfo }} -->
    <!-- 环境匹配 -->
    <img
      :src="projectInfo.h5_ad"
      v-if=" (projectInfo.open_type == '' || payment_type_ext == projectInfo.open_type ) && payment_type_ext != '' "
    />
    <!-- 环境不匹配 -->

    <div class="show-qrcode" v-if="(projectInfo.open_type == '' && payment_type_ext =='') || (projectInfo.open_type != '' && projectInfo.open_type != payment_type_ext) ">
      <div v-if="show_tip" class="title">{{projectInfo.school_name}}</div>
      <div v-if="show_tip" class="qrcode">
        <div class="qrcode-bg"><img :src="projectInfo.project_qrcode" alt=""></div>

        <div class="tip">
          <div class="pay-tip">
            <span>操作步骤：</span>
            <div>1.<span class="red">长按图片</span>，保存到相册</div>
            <div class="tip-1">2.打开  <span class="red">{{open_type_text}}，点击”扫一扫“ </span>,选择相册，选中保存的二维码，进入项目</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <img
      src="../../assets/img/weixin.jpg"
      v-if="payment_type_ext == 'alipay' && projectInfo.open_type == 'wxpay'"
    />
    <img
      src="../../assets/img/zhifubao.jpg"
      v-if="payment_type_ext == 'wxpay' && projectInfo.open_type == 'alipay'"
    /> -->
  </div>
</template>

<script>
import { getProjectInfo } from "@/api/standard/index.js";
import { Dialog } from "vant";
import configopenid from "@/utils/wxconfig.js";
export default {
  name: "StandardIndex",
  data() {
    return {
      tipText: "获取项目信息验证是否登录",
      payment_type_ext: "",
      projectInfo: {},
      project_open_type: "",
      show_tip:false,
      open_type_text:"微信或支付宝"
    };
  },
  created() {
    window.localStorage.clear();
  },
  mounted() {
    this.clientType();
  },
  methods: {
    clientType() {
      let ua = navigator.userAgent.toLowerCase();
      ua = ua.toLowerCase();
      let payment_type_ext = "";
      //payment_type_ext = "alipay";
      if (ua.match(/micromessenger/i) == "micromessenger") {
        payment_type_ext = "wxpay";
      }

      //判断是不是支付宝
      if (ua.match(/alipayclient/i) == "alipayclient") {
          payment_type_ext = "alipay";
      }

      if(!configopenid.isline)
        payment_type_ext = "alipay";
      
      if (!configopenid.isline) {
        payment_type_ext = "wxpay";
        this.projectInfo.open_type == "wxpay";
      }
      localStorage.setItem("payment_type_ext", payment_type_ext);
      this.payment_type_ext = payment_type_ext;
      this.getUrlParams().then(() => {
        this.getProjectInfo();
      });
    },

    /* 获取url参数 */
    getUrlParams() {
      return new Promise((resolve) => {
        let url_string = window.location.href; //window.location.href
        let url = new URL(url_string);
        console.log(url);
        let project_hash = url.searchParams.get("project_hash");
        console.log(project_hash);
        this.project_hash = project_hash;
        resolve();
      });
    },

    getJumpRoute(login_type = 1){
      let r = '';
      if (login_type == 1) {
        r = "/noRegistForm"
      } else if (login_type == 2) {
        r = "/onlyLogin" ;
      } else if (login_type == 3) {
        r = "/registLogin";
      }

      return r ;
    },
    /* 获取项目信息 */
    getProjectInfo() {
      // alert(this.payment_type_ext);
      let info = {
        open_type: this.payment_type_ext,
        project_hash: this.project_hash,
      };
      let data = {
        request: JSON.stringify(info),
      };
      getProjectInfo(data).then((res) => {
        console.log("获取项目信息", res);
        if(res.code != 0){
          return ; //返回
        }
        this.projectInfo = res.data;
        this.project_open_type = this.projectInfo.open_type ;
        let open_type_text = '微信或支付宝';
        open_type_text = this.projectInfo.open_type == 'alipay' ? '支付宝' : open_type_text;
        open_type_text = this.projectInfo.open_type == 'wxpay' ? '微信' : open_type_text;
        this.open_type_text = open_type_text;
        // alert(this.projectInfo.open_type);
        localStorage.setItem("projectInfo", JSON.stringify(res.data));
        
        if(this.projectInfo.open_type != "" && this.payment_type_ext != this.projectInfo.open_type){
          this.show_tip = true ;
          return ; // 返回
        }
        if(this.projectInfo.open_type == "" && this.payment_type_ext == ''){
          this.show_tip = true ;
          return ; // 返回
        }
        let r = this.getJumpRoute(res.data.login_type);

        if(this.projectInfo.mask_status != 1){
          if(!configopenid.isline){
            this.$router.push(r);
            return ;
          }
          this.redirectRouter(res.data.openurl)
          return ;
        }

        Dialog.alert({
          title: this.projectInfo.mask_title,
          message: this.projectInfo.mask_content,
          theme: "round-button",
          confirmButtonColor: "#1989fa",
          messageAlign: "left",
        }).then(() => {
          if (configopenid.isline) {
            this.redirectRouter(res.data.openurl)
          } else {
            //let r = this.getJumpRoute(res.data.login_type);
            this.$router.push(r);
          }
        });
      });
    },
    redirectRouter(openurl){
      setTimeout(() => {
            if (this.payment_type_ext == "wxpay") {
              location.href = openurl; //跳转wx||ali
            } else if (this.payment_type_ext == "alipay") {
              location.href = openurl
            }
        }, 1500);
    }
  },
};
</script>

<style lang="less" scoped>
.show-qrcode{
  width:100%;
  display: flex;flex-direction: column;
  justify-content: center;align-items: center;
  padding-top: 50px;
  
}

.show-qrcode .title{text-align: center;padding:20px 0;color:white;font-weight: bold;font-size: 0.5rem;padding-bottom: 20px;}
.show-qrcode .qrcode{width: 90%;background-color: #fff;border-radius: 0.4rem;display: flex;flex-direction: column;align-items: center;align-items: center;padding:20px 0;}
.qrcode-bg{ width:5.5rem;height:5.5rem;
  background-image: url("../../assets/img/qrcode_bg.jpg");background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;

display:flex;flex-direction: column;align-items: center;justify-content: center;}
.show-qrcode .qrcode img{width: 90%;}
.show-qrcode .tip{
  width: 90%;
  margin-top: 20px;
  padding-top:20px;
  border-top:3px dashed #ccc ;
}
.red{color:red;}

.pay-tip{display: flex;flex-direction: column;padding-bottom:30px;}
.pay-tip div{padding-top:20px;}
.pay-tip  .tip-1{line-height: 30px;}
.text-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background:#4492FE;
  /* letter-spacing: 0.34rem;
  writing-mode: tb-rl;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.68rem; */
}
.text-box img {
  width: 100%;
  // height: 100%;
}
</style>
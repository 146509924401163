import request from '@/utils/request_base.js'
/* 获取项目信息接口 */
const getProjectInfo = (data) => {
	return request({
		url: '/h5.index.project.info',
		method: 'post',
		data: data
	})
}
export {
	getProjectInfo
}
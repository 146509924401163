<template>
  <div v-cloak>
    <div class="main">
      <div class="box">
        <span>手机号：</span>
        <input
          type="number"
          placeholder="请输入手机号"
          autocomplete="username"
          v-model="username"
        />
      </div>
      <div class="box">
        <span>验证码：</span>
        <input type="number" placeholder="请输入验证码" v-model="msgcode" />
        <span class="fr" @click="getMsgCode">{{ timerNumber }}{{ ts }}</span>
      </div>
      <div class="box">
        <span>密码</span>
        <input
          type="password"
          placeholder="请输入密码"
          autocomplete="new-password"
          v-model="password"
        />
      </div>
      <div class="box">
        <span>确认密码</span>
        <input
          type="password"
          placeholder="请确认密码"
          autocomplete="new-password"
          v-model="newpassword"
        />
      </div>
      <div class="btn-box">
        <div class="btn-reportinfo" @click="getRegister">完成</div>
      </div>
    </div>
    <div style="height: 0.5rem"></div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getSMSCode, getRegister, getReset } from "@/api/login.js";
export default {
  name: "RegisterIndex",
  data() {
    return {
      isClickCode: true,
      username: "",
      password: "",
      newpassword: "",
      msgcode: "",
      timerNumber: "获取验证码",
      ts: "",
      projectInfo: "",
    };
  },
  mounted() {
    let projectInfo = localStorage.getItem("projectInfo");
    this.projectInfo = JSON.parse(projectInfo);
  },
  methods: {
    /* 计时 */
    timer() {
      this.isClickCode = false;
      this.isCode = true;
      var self = this;
      self.timerNumber = 60;
      self.ts = "s";
      var set = setInterval(function () {
        if (self.timerNumber == 1) {
          self.timerNumber = "获取短信验证码";
          self.msgcode = "";
          self.isClickCode = true;
          self.ts = "";
          clearInterval(set);
          return false;
        }
        self.timerNumber--;
      }, 1000);
      setTimeout(function () {
        self.isCode = false;
      }, 2000);
    },
    /* 获取code */
    getMsgCode() {
      console.log('获取验证码')
      if (!this.isClickCode) {
        return false;
      }
      if (this.username == "") {
        Toast("手机号不能为空");
        return false;
      }
      this.getSMSCode();
    },
    /* 请求验证码 */
    getSMSCode() {
      let action = this.$route.query.action;
      let info = {
        action: action,
        project_id: this.projectInfo.project_id,
        mobile: this.username,
      };
      let data = {
        request: JSON.stringify(info),
      };
      console.log(data);
      getSMSCode(data).then((res) => {
        console.log("验证码", res);
        if (res.code == 0) {
          this.timer();
        }
      });
    },
    /* 注册||修改密码 */
    getRegister() {
      if (this.username == "") {
        Toast("手机号不能为空");
        return false;
      }
      if (this.msgcode == "") {
        Toast("验证码不能为空");
        return false;
      }
      if (this.password == "") {
        Toast("密码不能为空");
        return false;
      }
      if (this.newpassword == "") {
        Toast("确认密码不能为空");
        return false;
      }
      if (this.password == this.newpassword) {
        let action = this.$route.query.action;
        console.log("接受参数", action);
        let info = {
          action: action,
          project_id: this.projectInfo.project_id,
          mobile: this.username,
          passwd: this.password,
          passwd2: this.newpassword,
          verify_code: this.msgcode,
        };
        let data = {
          request: JSON.stringify(info),
        };
        if (action == "register") {
          getRegister(data).then((res) => {
            console.log("注册", res);
            if (res.code == 0) {
              localStorage.setItem("header", JSON.stringify(res.data));
              this.$router.push("/report");
            } else {
              Toast(res.msg);
            }
          });
        } else if (action == "reset") {
          getReset(data).then((res) => {
            console.log("重置", res);
            if (res.code == 0) {
              Toast(res.msg);
              setTimeout(() => {
                // this.$router.push("/");
                // localStorage.setItem("header", JSON.stringify(res.data));
                console.log("header", res.data);
                this.$router.push({
                  path: "/",
                  query: {
                    project_hash: JSON.parse(
                      localStorage.getItem("projectInfo")
                    ).project_hash,
                  },
                });
              }, 2000);
            } else {
              Toast(res.msg);
            }
          });
        }
      } else {
        Toast("两次密码不一致");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 100%;
  border: 1px solid #dedede;
  margin-bottom: 0.3rem;
  overflow: hidden;
  display: flex;
  background: rgba(255, 255, 255, 1);
}

.box input {
  flex: 1;
  padding-right: 0.25rem;
  font-size: 0.3rem;
  width: 100%;
  border: 0;
}

.box span {
  padding-left: 0.2rem;
  width: 1.9rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.box .fr {
  padding-right: 0.25rem;
  display: inline-block;
  width: 1.8rem;
  right: 0;
  left: inherit;
  font-size: 0.26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(69, 102, 255, 1);
}
.btn-box {
  width: 6.6rem;
  height: 0.88rem;
  margin: 0 auto;
}

.btn-login {
  width: 3rem;
  height: 0.88rem;
  background-color: #09bb07;
  font-size: 0.36rem;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 0.6rem;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  float: left;
}

.btn-reportinfo {
  width: 6.6rem;
  height: 0.88rem;
  background-color: #2ab1f3;
  font-size: 0.36rem;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 0.6rem;
  border-radius: 0.1rem;
  text-align: center;
  line-height: 0.88rem;
  float: right;
}
</style>

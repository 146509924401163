import request from "@/utils/request.js";

const get_billList = (data) => {
  return request({
    url: "/h5.dingtalk.query.bill",
    method: "post",
    data: data,
  });
};

const get_billInfo = (data) => {
  return request({
    url: "/h5.dingtalk.bill.info",
    method: "post",
    data: data,
  });
};
export { get_billList, get_billInfo };

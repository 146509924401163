<template>
  <div>
    <div class="back" @click="backClick">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">添加信息</div>
    </div>
    <div class="title">请填写真实的学生信息</div>
    <div class="school_box">
      <van-field
        v-model="school"
        label="初中学校"
        placeholder="请输入初中就读学校名称"
        input-align="right"
      />
    </div>
    <div class="count">
      <van-field
        label="姓名"
        v-model="name"
        placeholder="请输入姓名"
        input-align="right"
      />
      <div class="sex_box">
        <div style="color: #646566">性别</div>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-radio name="1">男</van-radio>
          <van-radio name="2">女</van-radio>
        </van-radio-group>
      </div>
      <!-- <van-field
        v-model="id_card"
        label="学生身份证号"
        placeholder="请输入学生身份证号"
        input-align="right"
      /> -->
      <van-field
        v-model="mobile"
        type="number"
        label="家长手机号"
        placeholder="请输入家长手机号"
        input-align="right"
        maxlength="11"
      />
    </div>
    <div class="school_box">
      <van-field
        type="digit"
        v-model="score"
        label="一模成绩"
        placeholder="请输入一模成绩分数"
        input-align="right"
      />
    </div>
    <div class="btn" @click="submit">提交</div>
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
import { studentAdd } from "@/api/xinhua/project_list.js";
export default {
  data() {
    return {
      projectId: "",
      school: "",
      name: "",
      radio: "1",
      mobile: "",
      score: "",
      // id_card: "",
    };
  },
  mounted() {
    this.projectId = this.$route.query.project_id;
  },
  methods: {
    //提交
    submit() {
      // const _IDRe18 =
      //   /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      // const _IDre15 =
      //   /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;

      if (this.school == "") {
        Toast("请输入学校名称");
        return;
      }
      if (this.name == "") {
        Toast("请输入学生姓名");
        return;
      }
      // if (this.id_card == "") {
      //   Toast("请输入学生身份证号");
      //   return;
      // } else if (!_IDRe18.test(this.id_card) && !_IDre15.test(this.id_card)) {
      //   Toast("身份证号格式错误");
      //   return
      // }
      if (this.mobile == "") {
        Toast("请输入家长手机号");
        return;
      }
      if (this.score == "") {
        Toast("请输入一模成绩");
        return;
      }
      let headers = {
        token: JSON.parse(localStorage.getItem("teacherInfo")).token,
      };
      let val = {
        project_id: this.projectId,
        finish_school: this.school,
        name: this.name,
        gender: this.radio,
        mobile: this.mobile,
        last_score: this.score,
        // id_card: this.id_card,
      };
      const data = {
        request: JSON.stringify(val),
      };
      studentAdd(headers, data).then((res) => {
        if (res.code != 0) {
          if (res.code == 4006) {
            Dialog.confirm({
              title: "提示",
              message: res.msg,
            })
              .then(() => {
                this.$router.push({
                  path: "/addRemark",
                  query: {
                    info: val,
                    primary_name: res.primary_name,
                    user_name: res.username,
                  },
                });
              })
              .catch(() => {});
            return;
          }
          Toast(res.msg);
          return;
        }
        Dialog.alert({
          message: "提交成功",
        }).then((res) => {
          this.$router.go(-1);
          console.log(res, "提交");
        });
      });
    },
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image {
  width: 20px;
  height: 20px;
  margin-left: 0.2rem;
}
.back_txt {
  margin: 0 35%;
  text-align: center;
}
.title {
  color: #a7a7a7;
  font-size: 16px;
  padding: 20px;
}
.school_box {
  width: 335px;
  height: 62px;
  border-radius: 10px;
  background: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.count {
  width: 335px;
  border-radius: 10px;
  background: #fff;
  margin: 0.2rem auto;
}
.sex_box {
  display: flex;
  align-items: center;
  margin: 0 0.28rem;
  justify-content: space-between;
  border-bottom: 0.3px solid #eeee;
  padding: 0.28rem 0;
}
.btn {
  width: 339px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: #4581fc;
  margin: 0.5rem auto;
  text-align: center;
  color: #fff;
  font-weight: bold;
}
</style>
<template>
  <div class="warp" id="warp">
    <div class="advice_note" v-if="isShow" ref="reference">
      <div class="advice_note_header">
        <div class="advice_note_title">{{ stuInfo.advince_title }}</div>
      </div>
      <div class="advice_note_section">
        <p>{{ stuInfo.name }}(注册号:{{ stuInfo.student_id }})同学：</p>
        <div v-html="stuInfo.advince_content"></div>
      </div>
      <div class="advice_note_footer">
        <p>{{stuInfo.school_name}}</p>
        <p>{{stuInfo.advince_date}}</p>
      </div>
    </div>
    <div class="create_img" v-else>
      <img :src="downLoadUrl" />
    </div>
    <div class="create_footer">请长按上方图片保存</div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { Toast } from "vant";
import { getUserInfo } from "@/api/standard/onlyLogin.js";
import { uploadNotice } from "@/api/standard/common.js";
export default {
  data() {
    return {
      isShow: true,
      downLoadUrl: "",
      headerInfo: "",
      projectInfo: "",
      stuInfo: {
        advince_content: "",
      },
    };
  },
  mounted: function () {
    let headerInfo = localStorage.getItem("headerInfo");
    let projectInfo = localStorage.getItem("projectInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);
    this.getUserInfo().then(() => {
      this.createImg();
    });
  },

  methods: {
    /* 获取用户信息 */
    getUserInfo() {
      let headers = {
        token: this.headerInfo.token,
      };
      return new Promise((resolve) => {
        getUserInfo(headers).then((res) => {
          if (res.code == 0) {
            this.stuInfo = res.data;
            console.log(this.stuInfo);
            resolve();
          } else {
            Toast(res.data.msg);
          }
        });
      });
    },
    /* 上传图片生成图片连接 */
    createImg() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "图片生成中...",
      });
      html2canvas(this.$refs.reference, {
        dpi: window.devicePixelRatio,
        useCORS: true,
      }).then((canvas) => {
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        console.log(pageData);
        let data = {
          request: JSON.stringify({ file: pageData }),
        };
        let headers = {
          token: this.headerInfo.token,
        };
        uploadNotice(headers, data).then((res) => {
          console.log("上传通知书", res);
          Toast.clear();
          if (res.code == 0) {
            this.downLoadUrl = res.data.filename;
            this.isShow = false;
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.warp {
  width: 100%;
  height: 100%;
  padding: 0.24rem;
  box-sizing: border-box;
}
.advice_note {
  width: 100%;
  height: auto;
  border: 1px solid #f3f3f3;
}
.advice_note_header {
  width: 100%;
  height: auto;
  background: #fff8f9;
  padding: 0.4rem 0;
  box-sizing: border-box;
}
.advice_note_name {
  width: 100%;
  text-align: center;
  font-size: 0.32rem;
}
.advice_note_title {
  width: 100%;
  text-align: center;
  font-size: 0.36rem;
  margin-top: 0.3rem;
  color: #f76260;
}
.advice_note_section {
  width: 100%;
  height: auto;
  padding: 0.5rem;
  box-sizing: border-box;
}
p {
  font-size: 0.32rem;
  margin-top: 0.1rem;
  line-height: 0.5rem;
}
.notice {
  text-indent: 0.7rem;
}
.advice_note_footer {
  width: 100%;
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  box-sizing: border-box;
}
.create_img {
  width: 100%;
  height: auto;
}
img {
  width: 100%;
  height: auto;
}
.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading > img {
  width: 0.34rem;
  height: 0.34rem;
}
.loading_title {
  color: #fff;
  font-size: 0.32rem;
  margin-top: 0.3rem;
}
.create_footer {
  width: 100%;
  text-align: center;
  font-size: 0.32rem;
  margin-top: 0.4rem;
  color: #09bb07;
}
</style>

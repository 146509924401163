import request from '@/utils/request.js'
/* 获取城市信息接口 */
/* const getPayInfo = (data) => {
	return request({
		url: '/h5.index.order.info',
		method: 'post',
		data: data
	})
} */
/* 获取支付信息 */
const getOrderInfo = (data) => {
	return request({
		url: '/h5.index.order.info',
		method: 'post',
		data: data
	})
}
/* 订单列表 */
const orderList = (data) => {
	return request({
		url: '/h5.index.order.list',
		method: 'post',
		data: data
	})
}
/* 订单详情 */
const orderDetails = (data) => {
	return request({
		url: '/h5.index.order.detail',
		method: 'post',
		data: data
	})
}
/*支付 */
const payment = (data) => {
	return request({
			url: '/h5.index.order.h5payment',
			method: 'post',
			data
	})
}
export {
	getOrderInfo,
	orderList,
	orderDetails,
	payment

}
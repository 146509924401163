<template>
  <div class="content">
    <img :src="img_url" @click="showImg" />
  </div>
</template>

<script>
import { getNotification } from "@/api/standard/onlyLogin.js";
import { Toast, ImagePreview } from "vant";
export default {
  name: "notificationImage",
  data() {
    return {
      stuInfo: "",
      projectInfo: "",
      img_url: "",
    };
  },
  mounted() {
    let projectInfo = localStorage.getItem("projectInfo");
    let headerInfo = localStorage.getItem("headerInfo");
    this.projectInfo = JSON.parse(projectInfo);
    this.headerInfo = JSON.parse(headerInfo);
    this.getNotification();
  },
  methods: {
    /* 下载通知书 */
    getNotification() {
      let headers = {
        token: this.headerInfo.token,
      };
      let info = {
        project_id: this.projectInfo.project_id,
      };
      let data = {
        request: JSON.stringify(info),
      };
      getNotification(headers, data).then((res) => {
        console.log("获取通知书", res);
        if (res.code == 0) {
          this.img_url = res.data;
        } else {
          Toast(res.msg);
        }
      });
    },
    /* 下载通知书 */
    showImg() {
      ImagePreview([this.img_url]);
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
</style>

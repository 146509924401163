<template >
  <div style="overflow-y: hidden; height: 100%">
    <div style="position: relative">
      <div class="head"></div>
      <div class="content">
        <div class="title">{{ infoList.project_name }}</div>
        <div class="money">￥{{ infoList.total_money }}</div>
        <van-divider />
        <div class="box">
          <div class="left">姓名：</div>
          <div style="color: #222222">{{ infoList.stu_name }}</div>
        </div>
        <div class="box">
          <div class="left">学校：</div>
          <div style="color: #222222">{{ infoList.school_name }}</div>
        </div>
        <div class="box">
          <div class="left">班级：</div>
          <div style="color: #222222">{{ infoList.classes }}</div>
        </div>
        <div class="box">
          <div class="left">截止时间：</div>
          <div style="color: #222222">{{ infoList.bill_end_time }}</div>
        </div>
        <div
          class="btn"
          v-if="status != 4"
          @click="goPay(infoList.school_qrcode)"
        >
          查看缴费方式
        </div>
      </div>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper">
          <div class="img_box">
            <img :src="img_url" />
          </div>
          <div class="img_btn" @click="show = false">关闭</div>
        </div>
      </van-overlay>
    </div>
  </div>
</template>
<script>
import { get_billInfo } from "@/api/DD/bill.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      project_id: "",
      infoList: {
        stu_name: "",
        school_name: "",
        classes: "",
        bill_end_time: "",
        total_money: "",
        project_name: "",
        school_qrcode: "",
      },
      show: false,
      img_url: "",
      status: "",
    };
  },
  mounted() {
    this.project_id = this.$route.query.project_id;
    this.status = this.$route.query.status;
    this.getInfo();
  },
  methods: {
    getInfo() {
      const data = {
        project_bill_id: this.project_id,
      };
      get_billInfo({ request: JSON.stringify(data) }).then((res) => {
        console.log(res, "详情");
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        this.infoList = res.data;
      });
    },
    goPay(url) {
      this.show = true;
      this.img_url = url;
    },
  },
};
</script>


<style scoped>
.head {
  height: 211px;
  background: url("../../assets/img/iconbeg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.content {
  border-radius: 30px 30px 0 0;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 88px;
  height: 100vh;
}
.title {
  font-family: ".PingFang SC";
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #333;
  padding-top: 37px;
}
.money {
  font-family: ".PingFang SC Medium";
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: #222;
  padding-top: 17px;
}
.box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
}
.left {
  font-family: ".PingFang SC";
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: #818181;
}
.btn {
  width: 335px;
  height: 44px;
  line-height: 44px;
  border-radius: 22px;
  background: #4581fc;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
  margin: 50px auto;
}
.wrapper {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  height: 100%;
}
.img_box {
  background: rgb(255, 255, 255);
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: center;
  margin-top: 50%;
}
.img_btn {
  text-align: center;
  font-size: 18px;
  background: #499fff;
  color: #fff;
  width: 100px;
  height: 30px;
  line-height: 30px;
  border-radius: 20px;
  margin: 20px auto;
}
</style>
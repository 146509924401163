<template>
  <div>
    <div class="back" @click="backClick">
      <img class="back_image" src="../../assets/img/back.png" alt="" />
      <div class="back_txt">缴费码</div>
    </div>
    <div class="name">{{ projectName }}</div>
    <img class="code" :src="projectCode" alt="" />
    <div class="title">提示：学生缴费开关打开后才能支付</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectName: "",
      projectCode: "",
    };
  },
  mounted() {
    this.projectName = this.$route.query.list_name;
    this.projectCode = this.$route.query.url;
  },
  methods: {
    backClick() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.back {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 0.83rem;
  line-height: 0.83rem;
}
.back_image {
  width: 20px;
  height: 20px;
  margin-left: 0.2rem;
}
.back_txt {
  margin: 0 35%;
  text-align: center;
}
.name {
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  padding: 0.2rem;
}
.code {
  width: 50%;
  height: 50%;
  margin: 0 25%;
}
.title {
  text-align: center;
  color: red;
  padding: 0.3rem;
}
</style>
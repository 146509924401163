<template>
  <div style="position: relative">
    <div class="head">
      <div class="head_box">
        <div>您好，</div>
        <div>{{ phone }}用户</div>
      </div>
    </div>
    <div class="content" v-loading="load">
      <div
        class="box"
        v-for="(item, index) in list"
        :key="index"
        @click="infoChange(item)"
      >
        <div
          :class="
            item.status == 2
              ? 'box_top_n'
              : item.status == 4
              ? 'box_top_y'
              : 'box_top'
          "
        >
          <div class="box_title">{{ item.project_name }}</div>
          <img
            class="box_img"
            v-if="item.status == 2"
            src="../../assets/img/icon_not.png"
            alt=""
          />
          <img
            class="box_img"
            v-if="item.status == 4"
            src="../../assets/img/icon_yes.png"
            alt=""
          />
        </div>
        <div class="title">
          姓名：<span class="title" style="color: #222222">{{
            item.stu_name
          }}</span>
        </div>
        <div class="title">
          学校名称：<span class="title" style="color: #222222">{{
            item.school_name
          }}</span>
        </div>
        <van-divider />
        <div class="money">
          金额：<span class="txt">{{ item.total_money }}</span>
        </div>
      </div>
      <van-empty v-if="list.length == 0" description="暂无数据" />
    </div>
  </div>
</template>
<script>
import { get_billList } from "@/api/DD/bill.js";
import { Toast } from "vant";
export default {
  data() {
    return {
      mobile: "",
      phone: "",
      list: [],
      load: true,
      corp_id: "",
    };
  },
  mounted() {
    this.mobile = this.$route.query.mobile;
    this.corp_id = this.$route.query.auth_corp_id;
    this.phone =
      this.$route.query.mobile.substring(0, 3) +
      "****" +
      this.$route.query.mobile.substr(-4);

    this.getList();
  },
  methods: {
    getList() {
      this.load = true;
      const data = {
        parent_mobile: this.mobile,
        auth_corp_id: this.corp_id,
      };
      get_billList({ request: JSON.stringify(data) }).then((res) => {
        this.load = false;
        console.log(res, "数据列表");
        if (res.code != 0) {
          return Toast.fail(res.msg);
        }
        this.list = res.bill_list;
      });
    },
    infoChange(val) {
      this.$router.push({
        path: "/Ding/BillInfo",
        query: {
          project_id: val.project_bill_id,
          status: val.status,
        },
      });
    },
  },
};
</script>


<style scoped>
.head {
  height: 211px;
  background: url("../../assets/img/icon_beg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.head_box {
  font-family: ".PingFang SC";
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  color: #fff;
  text-shadow: 0 2px 3px #24242459;
  position: absolute;
  left: 20px;
  top: 90px;
  line-height: 25px;
}
.content {
  border-radius: 30px 30px 0 0;
  background: #fff;
  position: absolute;
  width: 100%;
  top: 181px;
  height: 100vh;
}
.box {
  width: 335px;
  height: 165px;
  border-radius: 17px;
  background: #fff;
  box-shadow: 0 4px 10px #bad0eca3;
  margin: 20px auto;
}
.box_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 46px;
  line-height: 46px;
  border-radius: 17px 17px 0 0;
}
.box_top_n {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 46px;
  line-height: 46px;
  border-radius: 17px 17px 0 0;
  background: linear-gradient(270deg, #fff 0%, #fef0ee 100%);
}
.box_top_y {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  height: 46px;
  line-height: 46px;
  border-radius: 17px 17px 0 0;
  background: linear-gradient(270deg, #fff 0%, #f1faf2 100%);
}
.box_title {
  font-family: ".PingFang SC Medium";
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #101724;
}
.box_img {
  width: 48px;
  height: 21px;
  background: transparent;
}
.title {
  color: #818181;
  font-size: 14px;
  font-weight: 400;
  padding: 10px 12px;
}
.money {
  color: #818181;
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  padding-right: 12px;
}
.txt {
  color: #ff2c23;
  font-size: 16px;
}
.van-divider {
  margin: 10px 0 !important;
}
</style>